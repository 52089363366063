import { React } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import "./styles.scss";
import { useAssets, useMobile } from "../../hooks/index";

const SignUp = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  return (
    <div className="background">
      {isMobile ? (
        <img
          src={getFile("Logo")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "50vw",
            zIndex: "3",
          }}
        />
      ) : (
        <img
          src={getFile("LightLogo")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "20vw",
          }}
        />
      )}
      <div className="signUp-box">
        <h1>Create Account </h1>

        <Form
          // size="m"
          name="signUp"
          layout={"vertical"}
          style={{ maxWidth: 900, width: "80%" }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input disabled={true} value={"Z.ghanbari14@gmail.com"} />
          </Form.Item>
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm-password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 10, span: 20 }}>
            <Button type="primary" htmlType="submit" size="large">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default SignUp;
