import { React, useState, useRef, useEffect } from "react";
import "./styles.scss";
import {
  Table,
  Button,
  Space,
  Input,
  Modal,
  Tag,
  Form,
  Select,
  InputNumber,
  Checkbox,
  Radio,
  Spin,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { useMobile } from "../../hooks";
import { fetchData, postData, deleteData, putData } from "../../endpoints";
import Highlighter from "react-highlight-words";
import FormItem from "antd/es/form/FormItem";
import AddService from "./addService";
import ServiceView from "./serviceView";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const ServiceManagement = () => {
  const isMobile = useMobile();
  const searchInput = useRef(null);
  const { confirm } = Modal;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [services, setServices] = useState([]);
  const [removedServices, setRemovedServices] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [event, setEvent] = useState([]);
  const [selectedService, setselectesService] = useState(null);
  const [ServiceHistory, setServiceHistory] = useState([]);
  const [expandedRowKeys, setexpandedRowKeys] = useState();
  const [newService, setNewService] = useState({
    steps: [],
    price: [],
    isEdit: true,
  });
  const [showRemove, setShowRemove] = useState(false);
  const [addService, setAddService] = useState(false);
  const [addCond, setAddCond] = useState(false);
  const [step, setStep] = useState({
    blocks: [],
    condition: { age: [], referral: {} },
  });
  const [t, setT] = useState(1);
  const [cond, setCond] = useState("block");
  const getEvents = async () => {
    var temp = [];
    var form = [];
    var appointment = [];
    var signForm = [];
    if (privilage.indexOf(access.formManegment.index) > -1) {
      var res1 = await fetchData("admin/form");
      if (res1.status == 200) {
        temp = temp.concat(res1.data.data);
        res1.data.data.map((f) => {
          temp = temp.concat(f.children);
        });
        form = temp;
      }
    }
    if (privilage.indexOf(access.formManegment.index) > -1) {
      var res2 = await fetchData("admin/sign-form");
      if (res2.status == 200) {
        temp = res2.data.data.filter((d) => {
          return !d.deletedAt;
        });
        signForm = temp;
      }
    }
    if (privilage.indexOf(access.eventManegment.index) > -1) {
      var res3 = await fetchData("admin/appointment-event");
      if (res3.status == 200) {
        temp = res3.data.data;
        appointment = temp;
      }
    }

    setEvent({ form: form, sign_form: signForm, appointment: appointment });
  };
  const getService = async (id) => {
    setIsLoading(true);
    var res = await fetchData("admin/service/" + id);
    if (res.status == 200) {
      setNewService(res.data.data);
    }
    setIsLoading(false);
  };

  const fetchServices = async (page = 1, reload = false) => {
    setIsLoading(true);
    var temp = services;
    if (reload) {
      temp = [];
    }
    if (services.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/service/?deletedAt=false&parentId=0&perPage=15&page=` + page
      );
      if (res) {
        var tempkey = res.data.data;
        tempkey.map((temp, index) => {
          res.data.data[index] = { ...temp, key: temp.id };
        });
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setServices(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat(res.data.data)
          );
        } else {
          setServices(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, { publishedAt: null, type: [] }])
          );
        }
      }
    }

    var res2 = await fetchData("admin/service-type");
    if (res2.status == 200) {
      setServiceTypes(res2.data.data);
    }
    setIsLoading(false);
  };
  const fetchServiceHistory = async (page = 1, reload = false, id) => {
    setIsLoading(true);
    var temp = ServiceHistory;
    if (reload) {
      temp = [];
    }
    if (ServiceHistory.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/service/?deletedAt=false&parentId=${id}&perPage=15&page=` + page
      );

      if (res) {
        var tempkey = res.data.data;
        tempkey.map((temp, index) => {
          res.data.data[index] = { ...temp, key: temp.id };
        });
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setServiceHistory(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat(res.data.data)
          );
        } else {
          setServiceHistory(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, { publishedAt: null, type: [] }])
          );
        }
      }
    }

    setIsLoading(false);
  };

  const fetchDeletedServices = async (page = 1, reload = false) => {
    setIsLoading(true);
    var temp = services;
    if (reload) {
      temp = [];
    }
    if (services.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/service/?deletedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setRemovedServices(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat(res.data.data)
          );
        } else {
          setRemovedServices(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, { publishedAt: null, type: [] }])
          );
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchServices();
    fetchDeletedServices();

    getEvents();
  }, []);
  const Diactive = async (service) => {
    setIsLoading(true);
    var res = await putData(`admin/service/${service.id}/deactivate`);
    if (res.status != 200) {
      Modal.error({ title: res });
    } else {
      await fetchServices(1, true);
    }
    setIsLoading(false);
  };
  const Duplicate = async (service) => {
    setIsLoading(true);
    var res = await postData(`admin/service/${service.id}/duplicate`);
    if (res.status != 201) {
      Modal.error({ title: res });
    } else {
      await fetchServices(1, true);
    }
    setIsLoading(false);
  };
  const getVersion = async (service) => {
    setIsLoading(true);
    var res = await fetchData(`admin/service/${service.id}/version`);
    if (res.status != 200) {
      Modal.error({ title: res });
    } else {
      console.log(res.data.data);
    }
    setIsLoading(false);
  };
  const newVersion = async (service) => {
    setIsLoading(true);
    var res = await putData(`admin/service/${service.id}/version/new`);
    if (res.status != 200) {
      Modal.error({ title: res });
    }
    if (res.status == 200) {
      var temp = await res.data;
      temp = temp.service;
      if (temp) {
        var res2 = await fetchData("admin/service/" + temp.id);
        if (res2.status == 200) {
          temp = res2.data.data;
          temp.steps.map((s, i) => {
            temp.steps[i] = { ...temp.steps[i], isEdit: false };
          });
          setT(1);
          setNewService({
            ...temp,
            isEdit: false,
            editService: true,
          });

          Modal.confirm({
            title:
              "The last version of this service has not been released yet, you can change it",
            // content:
            //   "By editing the published service, you will create a new version.",
            okText: "Continue",
            cancelText: "Cancel",
            onOk: async () => {
              setIsOpen(true);
            },
          });
        } else {
          Modal.error({ title: res2 });
        }
      } else {
        Modal.success({
          title: "New version for the service created successfully.",
        });
      }
    }
    setIsLoading(false);
  };
  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to remove this service?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };
  const publishService = async (id) => {
    setIsLoading(true);
    var res = await putData(`admin/service/${id}/publish`);
    if (res.status == 200) {
      myForm.resetFields();
      setNewService({
        steps: [],
        price: [],
        isEdit: true,
      });
      fetchServices();
      setIsOpen(false);
      setIsLoading(false);
      Modal.success({ title: res.data.message });
    } else {
      setIsLoading(false);
      Modal.error({ title: res });
    }
  };
  const showPublishConfirm = async (id) => {
    confirm({
      title:
        "Are you sure you want to publish this service? You cannot edit the service after publishing it.",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        publishService(id);
      },
      onCancel() {},
    });
  };
  const removeService = async (id) => {
    setIsLoading(true);
    var res = await deleteData(`admin/service/${id}`);
    if (res.status === 200) {
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };
  const removeGrupe = async (listOfForms) => {
    let isOk = true;
    await listOfForms.map(async (e) => {
      var res = await removeService(e.id);
      if (!res) {
        isOk = false;
      }
    });
    if (isOk) {
      await fetchServices();
      setSelectedRowKeys([]);
      Modal.success({ title: "Services have been successfully deleted." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setServices([])
     fetchServices(1,true)
    //  fetchServices(1,true)
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "40%" : "20%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
      width: "20%",
      responsive: ["md"],
      render: (types, record) => (
        <div className="d-flex gap-2">
          {types &&
            types.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];
              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
    },
    {
      title: "Published Date",
      dataIndex: "publishedAt",
      key: "publishedAt",
      width: "20%",
      responsive: ["md"],
      sorter: (a, b) => new Date(a.publishedAt) - new Date(b.publishedAt),
      sortDirections: ["descend", "ascend"],
      render: (publishedAt, record) =>
        publishedAt ? (
          <span>{new Date(publishedAt).toDateString()}</span>
        ) : (
          privilage.indexOf(access.serviceManegment.publish) > -1 && (
            <span
              style={{
                fontWeight: "700",
                color: "#2E6CDF",
                textAlign: "center",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                showPublishConfirm(record.id);
              }}
            >
              Publish
            </span>
          )
        ),
    },

    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div
          style={{ marginRight: "15px" }}
          dir="rtl"
          className="d-flex flex-row gap-4"
        >
          {privilage.indexOf(access.serviceManegment.delete) > -1 && (
            <span
              style={{
                color: "#BC2323",

                cursor: "pointer",
              }}
              onClick={async () => {
                await showDeleteConfirm([u]);
              }}
            >
              Remove
            </span>
          )}

          {privilage.indexOf(access.serviceManegment.show) > -1 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={async () => {
                setIsLoading(true);
                var res = await fetchData("admin/service/" + u.id);
                if (res.status == 200) {
                  setNewService({
                    ...res.data.data,
                    types: u.types,
                  });
                  setIsOpen2(true);
                } else {
                  Modal.error({ title: res });
                }
                setIsLoading(false);
              }}
            >
              View
            </span>
          )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 &&
            privilage.indexOf(access.serviceManegment.show) > -1 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  setIsLoading(true);
                  if (u.publishedAt) {
                    Modal.confirm({
                      title: "Are You Sure?",
                      content:
                        "By editing the published service, you will create a new version.",
                      okText: "Yes I’m sure",
                      cancelText: "Decline",
                      onOk: async () => {
                        await newVersion(u);
                      },
                    });
                  } else {
                    var res = await fetchData("admin/service/" + u.id);
                    if (res.status == 200) {
                      var temp = res.data.data;
                      temp.steps.map((s, i) => {
                        temp.steps[i] = { ...temp.steps[i], isEdit: false };
                      });
                      setT(1);
                      setNewService({
                        ...temp,
                        isEdit: false,
                        editService: true,
                      });
                      setIsOpen(true);
                    } else {
                      Modal.error({ title: res });
                    }
                  }
                  setIsLoading(false);
                }}
              >
                Edit
              </span>
            )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 &&
            !u.deactivatedAt && (
              <span
                style={{ cursor: "pointer", width: "50%", color: "red" }}
                onClick={async () => {
                  Modal.confirm({
                    title: "Are You Sure to Deactive this service?",

                    okText: "Yes I’m sure",
                    cancelText: "Decline",
                    onOk: async () => {
                      await Diactive(u);
                    },
                  });
                }}
              >
                Deactive
              </span>
            )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 &&
            u.deactivatedAt && (
              <span
                style={{ cursor: "pointer", width: "50%" }}
                // onClick={async () => {
                //   Modal.confirm({
                //     title: "Are You Sure to Deactive this service?",

                //     okText: "Yes I’m sure",
                //     cancelText: "Decline",
                //     onOk: async () => {
                //       await Diactive(u);
                //     },
                //   });
                // }}
              >
                {`${new Date(u.deactivatedAt).getFullYear()}/${
                  new Date(u.deactivatedAt).getMonth() + 1
                }/${new Date(u.deactivatedAt).getDate()}`}
              </span>
            )}
          {/* {privilage.indexOf(access.serviceManegment.edit) > -1 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await getVersion(u);
              }}
            >
              Version History
            </span>
          )} */}
          {privilage.indexOf(access.serviceManegment.edit) > -1 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={async () => {
                Modal.confirm({
                  title: "Are You Sure to Duplicate this service?",
                  okText: "Yes I’m sure",
                  cancelText: "Decline",
                  onOk: async () => {
                    await Duplicate(u);
                  },
                });
              }}
            >
              Duplicate
            </span>
          )}
        </div>
      ),
    },
  ];

  const History_columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "40%" : "20%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
      width: "20%",
      responsive: ["md"],
      render: (types, record) => (
        <div className="d-flex gap-2">
          {types &&
            types.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];
              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
    },
    {
      title: "Published Date",
      dataIndex: "publishedAt",
      key: "publishedAt",
      width: "20%",
      responsive: ["md"],
      sorter: (a, b) => new Date(a.publishedAt) - new Date(b.publishedAt),
      sortDirections: ["descend", "ascend"],
      render: (publishedAt, record) =>
        publishedAt ? (
          <span>{new Date(publishedAt).toDateString()}</span>
        ) : (
          privilage.indexOf(access.serviceManegment.publish) > -1 && (
            <span
              style={{
                fontWeight: "700",
                color: "#2E6CDF",
                textAlign: "center",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                showPublishConfirm(record.id);
              }}
            >
              Publish
            </span>
          )
        ),
    },

    // {
    //   title: "",
    //   dataIndex: "",
    //   key: "x",
    //   render: (u) => (
    //     <div
    //       style={{ marginRight: "15px" }}
    //       dir="rtl"
    //       className="d-flex flex-row gap-4"
    //     >
    //       {privilage.indexOf(access.serviceManegment.delete) > -1 && (
    //         <span
    //           style={{
    //             color: "#BC2323",

    //             cursor: "pointer",
    //           }}
    //           onClick={async () => {
    //             await showDeleteConfirm([u]);
    //           }}
    //         >
    //           Remove
    //         </span>
    //       )}

    //       {privilage.indexOf(access.serviceManegment.show) > -1 && (
    //         <span
    //           style={{ cursor: "pointer" }}
    //           onClick={async () => {
    //             setIsLoading(true);
    //             var res = await fetchData("admin/service/" + u.id);
    //             if (res.status == 200) {
    //               setNewService({
    //                 ...res.data.data,
    //                 types: u.types,
    //               });
    //               setIsOpen2(true);
    //             } else {
    //               Modal.error({ title: res });
    //             }
    //             setIsLoading(false);
    //           }}
    //         >
    //           View
    //         </span>
    //       )}
    //       {privilage.indexOf(access.serviceManegment.edit) > -1 &&
    //         privilage.indexOf(access.serviceManegment.show) > -1 && (
    //           <span
    //             style={{ cursor: "pointer" }}
    //             onClick={async () => {
    //               setIsLoading(true);
    //               if (u.publishedAt) {
    //                 Modal.confirm({
    //                   title: "Are You Sure?",
    //                   content:
    //                     "By editing the published service, you will create a new version.",
    //                   okText: "Yes I’m sure",
    //                   cancelText: "Decline",
    //                   onOk: async () => {
    //                     await newVersion(u);
    //                   },
    //                 });
    //               } else {
    //                 var res = await fetchData("admin/service/" + u.id);
    //                 if (res.status == 200) {
    //                   var temp = res.data.data;
    //                   temp.steps.map((s, i) => {
    //                     temp.steps[i] = { ...temp.steps[i], isEdit: false };
    //                   });
    //                   setT(1);
    //                   setNewService({
    //                     ...temp,
    //                     isEdit: false,
    //                     editService: true,
    //                   });
    //                   setIsOpen(true);
    //                 } else {
    //                   Modal.error({ title: res });
    //                 }
    //               }
    //               setIsLoading(false);
    //             }}
    //           >
    //             Edit
    //           </span>
    //         )}
    //       {privilage.indexOf(access.serviceManegment.edit) > -1 &&
    //         !u.deactivatedAt && (
    //           <span
    //             style={{ cursor: "pointer", width: "50%", color: "red" }}
    //             onClick={async () => {
    //               Modal.confirm({
    //                 title: "Are You Sure to Deactive this service?",

    //                 okText: "Yes I’m sure",
    //                 cancelText: "Decline",
    //                 onOk: async () => {
    //                   await Diactive(u);
    //                 },
    //               });
    //             }}
    //           >
    //             Deactive
    //           </span>
    //         )}
    //       {privilage.indexOf(access.serviceManegment.edit) > -1 &&
    //         u.deactivatedAt && (
    //           <span
    //             style={{ cursor: "pointer", width: "50%" }}
    //             // onClick={async () => {
    //             //   Modal.confirm({
    //             //     title: "Are You Sure to Deactive this service?",

    //             //     okText: "Yes I’m sure",
    //             //     cancelText: "Decline",
    //             //     onOk: async () => {
    //             //       await Diactive(u);
    //             //     },
    //             //   });
    //             // }}
    //           >
    //             {`${new Date(u.deactivatedAt).getFullYear()}/${
    //               new Date(u.deactivatedAt).getMonth() + 1
    //             }/${new Date(u.deactivatedAt).getDate()}`}
    //           </span>
    //         )}
    //       {privilage.indexOf(access.serviceManegment.edit) > -1 && (
    //         <span
    //           style={{ cursor: "pointer" }}
    //           onClick={async () => {
    //             await getVersion(u);
    //           }}
    //         >
    //           Version History
    //         </span>
    //       )}
    //       {privilage.indexOf(access.serviceManegment.edit) > -1 && (
    //         <span
    //           style={{ cursor: "pointer" }}
    //           onClick={async () => {
    //             Modal.confirm({
    //               title: "Are You Sure to Duplicate this service?",
    //               okText: "Yes I’m sure",
    //               cancelText: "Decline",
    //               onOk: async () => {
    //                 await Duplicate(u);
    //               },
    //             });
    //           }}
    //         >
    //           Duplicate
    //         </span>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const deletedColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "40%" : "20%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
      width: "20%",
      responsive: ["md"],
      render: (types, record) => (
        <div className="d-flex gap-2">
          {types &&
            types.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];
              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
    },
    {
      title: "deleted At",
      dataIndex: "deletedAt",
      key: "deletedAt",
      width: "20%",
      responsive: ["md"],
      sorter: (a, b) => new Date(a.publishedAt) - new Date(b.publishedAt),
      sortDirections: ["descend", "ascend"],
      render: (deletedAt, record) =>
        deletedAt ? (
          <span>{new Date(deletedAt).toDateString()}</span>
        ) : (
          <div></div>
        ),
    },

    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div
          style={{ marginRight: "15px" }}
          dir="rtl"
          className="d-flex flex-row gap-4"
        >
          {privilage.indexOf(access.serviceManegment.delete) > -1 && (
            <span
              style={{
                color: "#BC2323",

                cursor: "pointer",
              }}
              onClick={async () => {
                await showDeleteConfirm([u]);
              }}
            >
              Remove
            </span>
          )}

          {privilage.indexOf(access.serviceManegment.show) > -1 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={async () => {
                setIsLoading(true);
                var res = await fetchData("admin/service/" + u.id);
                if (res.status == 200) {
                  setNewService({
                    ...res.data.data,
                    types: u.types,
                  });
                  setIsOpen2(true);
                } else {
                  Modal.error({ title: res });
                }
                setIsLoading(false);
              }}
            >
              View
            </span>
          )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 &&
            privilage.indexOf(access.serviceManegment.show) > -1 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  setIsLoading(true);
                  if (u.publishedAt) {
                    Modal.confirm({
                      title: "Are You Sure?",
                      content:
                        "By editing the published service, you will create a new version.",
                      okText: "Yes I’m sure",
                      cancelText: "Decline",
                      onOk: async () => {
                        await newVersion(u);
                      },
                    });
                  } else {
                    var res = await fetchData("admin/service/" + u.id);
                    if (res.status == 200) {
                      var temp = res.data.data;
                      temp.steps.map((s, i) => {
                        temp.steps[i] = { ...temp.steps[i], isEdit: false };
                      });
                      setT(1);
                      setNewService({
                        ...temp,
                        isEdit: false,
                        editService: true,
                      });
                      setIsOpen(true);
                    } else {
                      Modal.error({ title: res });
                    }
                  }
                  setIsLoading(false);
                }}
              >
                Edit
              </span>
            )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 &&
            !u.deactivatedAt && (
              <span
                style={{ cursor: "pointer", width: "50%", color: "red" }}
                onClick={async () => {
                  Modal.confirm({
                    title: "Are You Sure to Deactive this service?",

                    okText: "Yes I’m sure",
                    cancelText: "Decline",
                    onOk: async () => {
                      await Diactive(u);
                    },
                  });
                }}
              >
                Deactive
              </span>
            )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 &&
            u.deactivatedAt && (
              <span
                style={{ cursor: "pointer", width: "50%" }}
                // onClick={async () => {
                //   Modal.confirm({
                //     title: "Are You Sure to Deactive this service?",

                //     okText: "Yes I’m sure",
                //     cancelText: "Decline",
                //     onOk: async () => {
                //       await Diactive(u);
                //     },
                //   });
                // }}
              >
                {`${new Date(u.deactivatedAt).getFullYear()}/${
                  new Date(u.deactivatedAt).getMonth() + 1
                }/${new Date(u.deactivatedAt).getDate()}`}
              </span>
            )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await getVersion(u);
              }}
            >
              Version History
            </span>
          )}
          {privilage.indexOf(access.serviceManegment.edit) > -1 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={async () => {
                Modal.confirm({
                  title: "Are You Sure to Duplicate this service?",
                  okText: "Yes I’m sure",
                  cancelText: "Decline",
                  onOk: async () => {
                    await Duplicate(u);
                  },
                });
              }}
            >
              Duplicate
            </span>
          )}
        </div>
      ),
    },
  ];

  const [IForm] = Form.useForm();
  const [myForm] = Form.useForm();
  return (
    <div
      dir="ltr"
      className={`testoverlay${
        isMobile
          ? "d-flex flex-column w-100"
          : "d-flex flex-column w-100 px-3 py-5"
      }`}
      style={{ backgroundColor: "white", minHeight: "100vh" }}
    >
      {isOpen ? (
        <>
          <AddService
            newService={newService}
            setNewService={setNewService}
            setIsOpen={setIsOpen}
            serviceTypes={serviceTypes}
            isLoading={isLoading}
            event={event}
            setIsLoading={setIsLoading}
            fetchServices={fetchServices}
            setAddCond={setAddCond}
            setStep={setStep}
            loading2={loading2}
            setLoading2={setLoading2}
            loading3={loading3}
            setLoading3={setLoading3}
          />
          <Modal
            open={addCond}
            okText="Confirm"
            loading={isLoading}
            onOk={async () => {
              if (
                privilage.indexOf(access.serviceManegment.addCondition) > -1
              ) {
                setIsLoading(true);
                var temp = newService.steps;
                temp[step.priority] = step;
                setNewService({ ...newService, steps: temp });
                var res2 = [];
                step.blocks.forEach(async (element, i) => {
                  if (element.id) {
                    var res3 = await deleteData(
                      "admin/service-step/" + step.id + "/block/" + element.id,
                      element
                    );
                  }
                  if (element.status) {
                    res2[i] = await postData(
                      "admin/service-step/" + step.id + "/block",
                      element
                    );
                  }

                  if (res2[i].status !== 201) {
                    Modal.error({
                      title:
                        "blocks on step " +
                        element.blockingStepId +
                        "has error: " +
                        res2[i],
                    });
                  } else {
                    var temp2 = step.blocks;
                    temp2[i] = { ...temp2[i], id: res2[i].data.data.id };
                    temp[step.priority] = { ...step, blocks: temp2 };
                    setNewService({ ...newService, steps: temp });
                  }
                });
                step.condition.age.forEach(async (element, i) => {
                  if (element.id) {
                    var res3 = await deleteData(
                      "admin/service-step/" +
                        step.id +
                        "/condition/" +
                        element.id,
                      element
                    );
                  }
                  if (element.value) {
                    res2[i] = await postData(
                      "admin/service-step/" + step.id + "/condition",
                      element
                    );
                  }

                  if (res2[i].status !== 201) {
                    Modal.error({
                      title: "condition on age " + "has error: " + res2[i],
                    });
                  } else {
                    var temp2 = step.condition.age;
                    temp2[i] = { ...temp2[i], id: res2[i].data.data.id };
                    temp[step.priority] = {
                      ...step,
                      condition: { ...step.condition, age: temp2 },
                    };
                    setNewService({ ...newService, steps: temp });
                  }
                });
                let element = step.condition.referral;
                if (element.value) {
                  if (element.id) {
                    var res3 = await deleteData(
                      "admin/service-step/" +
                        step.id +
                        "/condition/" +
                        element.id,
                      element
                    );
                  }
                  if (element.value != "optional") {
                    res2 = await postData(
                      "admin/service-step/" + step.id + "/condition",
                      element
                    );
                    if (res2.status !== 201) {
                      Modal.error({
                        title: "condition on referral " + "has error: " + res2,
                      });
                    } else {
                      var temp2 = step.condition.referral;
                      temp2 = { ...temp2, id: res2.data.data.id };
                      temp[step.priority] = {
                        ...step,
                        condition: { ...step.condition, referral: temp2 },
                      };
                      setNewService({ ...newService, steps: temp });
                    }
                  }
                }

                setStep({ blocks: [], condition: { age: [], referral: {} } });
                setAddCond(false);
                myForm.resetFields();
                setIsLoading(false);
              } else {
                Modal.error({ title: "You dont have permission" });
              }
            }}
            onCancel={() => {
              setStep({ blocks: [], condition: { age: [], referral: {} } });
              myForm.resetFields();
              setAddCond(false);
            }}
          >
            <div className="condition d-flex flex-column px-3 gap-3 py-3 w-100 align-items-center">
              <h2 style={{ marginBottom: "-5px" }}>Assign Condition</h2>
              <div style={{ marginBottom: "0" }} className="h-line" />
              <div className="w-100 d-flex  flex-column gap-3">
                <div className="d-flex flex-row gap-4 justify-content-center w-100">
                  <span
                    onClick={() => {
                      setCond("block");
                    }}
                    style={
                      cond == "block"
                        ? { color: "black", fontWeight: "700" }
                        : { color: "#D1D1D1", fontWeight: "600" }
                    }
                  >
                    Blocked By
                  </span>
                  <span
                    style={
                      cond == "age"
                        ? { color: "black", fontWeight: "700" }
                        : { color: "#D1D1D1", fontWeight: "600" }
                    }
                    onClick={() => {
                      setCond("age");
                    }}
                  >
                    Age
                  </span>
                  <span
                    style={
                      cond == "referral"
                        ? { color: "black", fontWeight: "700" }
                        : { color: "#D1D1D1", fontWeight: "600" }
                    }
                    onClick={() => {
                      setCond("referral");
                    }}
                  >
                    Referral
                  </span>
                </div>
                <div className="h-line" />
              </div>
              {cond == "block" ? (
                <>
                  {" "}
                  <h3>
                    Step{" "}
                    <strong style={{ color: "#2E6CDF" }}>
                      {" " + step.title + " "}
                    </strong>{" "}
                    is blocked until the status in:
                  </h3>
                  <Form
                    form={myForm}
                    className="d-flex flex-column gap-3"
                    style={{ width: "90%" }}
                  >
                    {step.blocks.map((c, i) => {
                      return (
                        <div
                          className="d-flex flex-row gap-3"
                          style={{ width: "100%" }}
                        >
                          <span>Step</span>
                          <Select
                            defaultValue={c.blockingStepId}
                            className="col-4"
                            onChange={(v) => {
                              var temp = step.blocks;
                              temp[i] = { ...temp[i], blockingStepId: v };

                              setStep({ ...step, blocks: temp });
                            }}
                          >
                            {newService.steps.map((s) => {
                              return (
                                <Select.Option value={s.id}>
                                  {s.title}
                                </Select.Option>
                              );
                            })}
                          </Select>
                          <span>is</span>
                          <Select
                            defaultValue={c.status}
                            className="col-4"
                            onChange={(v) => {
                              var temp = step.blocks;
                              temp[i] = { ...temp[i], status: v };

                              setStep({ ...step, blocks: temp });
                            }}
                          >
                            <Select.Option value={"done"}>Done</Select.Option>
                            <Select.Option value={"locked"}>
                              Locked
                            </Select.Option>
                            <Select.Option value={"missed"}>
                              Missed
                            </Select.Option>
                            <Select.Option value={"active"}>
                              Active
                            </Select.Option>
                            <Select.Option value={"need_payment"}>
                              Need payment
                            </Select.Option>
                            <Select.Option value={"need_admin_approve"}>
                              Need admin approve
                            </Select.Option>
                            <Select.Option value={"is_scheduled"}>
                              Is scheduled
                            </Select.Option>
                          </Select>

                          {isLoading2 ? (
                            <Spin />
                          ) : (
                            <h4
                              onClick={async () => {
                                setIsLoading2(true);
                                if (c.id) {
                                  var res = await deleteData(
                                    `admin/service-step/${step.id}/block/${c.id}`
                                  );
                                  if (res.status == 200) {
                                    var temp2 = step.blocks.filter((a) => {
                                      return a != c;
                                    });
                                    var temp = newService.steps;
                                    temp[step.priority] = {
                                      ...step,
                                      blocks: temp2,
                                    };
                                    setStep({ ...step, blocks: temp2 });
                                    setNewService({
                                      ...newService,
                                      steps: temp,
                                    });
                                  } else {
                                    setIsLoading2(false);
                                    Modal.error({
                                      title: res,
                                    });
                                  }
                                } else {
                                  var temp2 = step.blocks.filter((a) => {
                                    return a != c;
                                  });
                                  var temp = newService.steps;
                                  temp[step.priority] = {
                                    ...step,
                                    blocks: temp2,
                                  };
                                  setStep({ ...step, blocks: temp2 });
                                  setNewService({ ...newService, steps: temp });
                                }
                                setIsLoading2(false);
                              }}
                              style={{ color: "#BC2323", fontWeight: "700" }}
                            >
                              Delete
                            </h4>
                          )}
                        </div>
                      );
                    })}

                    {
                      <svg
                        onClick={() => {
                          setStep({ ...step, blocks: [...step.blocks, {}] });
                        }}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="32" height="32" rx="4" fill="#2E6CDF" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M27.0302 14.9328H17.0671V4.96968C17.0671 4.37996 16.5891 3.90244 15.9999 3.90244C15.4102 3.90244 14.9327 4.37996 14.9327 4.96968V14.9328H4.96958C4.37987 14.9328 3.90234 15.4103 3.90234 16C3.90234 16.5897 4.37987 17.0672 4.96958 17.0672H14.9327V27.0303C14.9327 27.6195 15.4102 28.0976 15.9999 28.0976C16.5891 28.0976 17.0671 27.6195 17.0671 27.0303V17.0672H27.0302C27.6194 17.0672 28.0975 16.5897 28.0975 16C28.0975 15.4103 27.6194 14.9328 27.0302 14.9328Z"
                          fill="white"
                        />
                      </svg>
                    }
                  </Form>
                </>
              ) : cond == "age" ? (
                <>
                  {" "}
                  <h3>Patient’s age must be:</h3>
                  <Form
                    form={myForm}
                    className="d-flex flex-column gap-3"
                    style={{ width: "90%" }}
                  >
                    {step.condition.age.map((c, i) => {
                      return (
                        <div
                          className="d-flex flex-row gap-3"
                          style={{ width: "100%" }}
                        >
                          <Select
                            defaultValue={step.condition.age[i].operator}
                            className="col-4"
                            onChange={(v) => {
                              var temp = step.condition.age;
                              temp[i] = { ...temp[i], operator: v };

                              setStep({
                                ...step,
                                condition: {
                                  ...step.condition,
                                  age: temp,
                                },
                              });
                            }}
                          >
                            <Select.Option value={"=="}>=</Select.Option>
                            <Select.Option value={">"}>{">"}</Select.Option>
                            <Select.Option value={"<"}>{"<"}</Select.Option>
                          </Select>
                          <InputNumber
                            defaultValue={step.condition.age[i].value}
                            onChange={(v) => {
                              var temp = step.condition.age;
                              temp[i] = { ...temp[i], value: v.toString() };

                              setStep({
                                ...step,
                                condition: {
                                  ...step.condition,
                                  age: temp,
                                },
                              });
                            }}
                            className="col-3"
                          />
                          <span>month(s)</span>
                          {isLoading2 ? (
                            <Spin />
                          ) : (
                            <h4
                              onClick={async () => {
                                setIsLoading2(true);
                                if (c.id) {
                                  var res = await deleteData(
                                    `admin/service-step/${step.id}/condition/${c.id}`
                                  );
                                  if (res.status == 200) {
                                    var temp2 = step.condition.age.filter(
                                      (a) => {
                                        return a != c;
                                      }
                                    );
                                    var temp = newService.steps;
                                    temp[step.priority] = {
                                      ...step,
                                      condition: {
                                        ...step.condition,
                                        age: temp2,
                                      },
                                    };
                                    setStep({
                                      ...step,
                                      condition: {
                                        ...step.condition,
                                        age: temp2,
                                      },
                                    });
                                    setNewService({
                                      ...newService,
                                      steps: temp,
                                    });
                                  } else {
                                    setIsLoading2(false);
                                    Modal.error({
                                      title: res,
                                    });
                                  }
                                } else {
                                  var temp2 = step.condition.age.filter((a) => {
                                    return a != c;
                                  });
                                  var temp = newService.steps;
                                  temp[step.priority] = {
                                    ...step,
                                    condition: {
                                      ...step.condition,
                                      age: temp2,
                                    },
                                  };
                                  setStep({
                                    ...step,
                                    condition: {
                                      ...step.condition,
                                      age: temp2,
                                    },
                                  });
                                  setNewService({ ...newService, steps: temp });
                                }
                                setIsLoading2(false);
                              }}
                              style={{ color: "#BC2323", fontWeight: "700" }}
                            >
                              Delete
                            </h4>
                          )}
                        </div>
                      );
                    })}

                    {
                      <svg
                        onClick={() => {
                          setStep({
                            ...step,
                            condition: {
                              ...step.condition,
                              age: [
                                ...step.condition.age,
                                { parameter: "age" },
                              ],
                            },
                          });
                        }}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="32" height="32" rx="4" fill="#2E6CDF" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M27.0302 14.9328H17.0671V4.96968C17.0671 4.37996 16.5891 3.90244 15.9999 3.90244C15.4102 3.90244 14.9327 4.37996 14.9327 4.96968V14.9328H4.96958C4.37987 14.9328 3.90234 15.4103 3.90234 16C3.90234 16.5897 4.37987 17.0672 4.96958 17.0672H14.9327V27.0303C14.9327 27.6195 15.4102 28.0976 15.9999 28.0976C16.5891 28.0976 17.0671 27.6195 17.0671 27.0303V17.0672H27.0302C27.6194 17.0672 28.0975 16.5897 28.0975 16C28.0975 15.4103 27.6194 14.9328 27.0302 14.9328Z"
                          fill="white"
                        />
                      </svg>
                    }
                  </Form>
                </>
              ) : (
                <>
                  {" "}
                  <h3>
                    In Step{" "}
                    <strong style={{ color: "#2E6CDF" }}>
                      {" " + step.title + " "}
                    </strong>{" "}
                    :
                  </h3>
                  <Form
                    form={myForm}
                    style={{
                      width: "90%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {console.log(step.condition.referral.value)}
                    <FormItem>
                      <Checkbox
                        checked={step.condition.referral.value == "true"}
                        onChange={(v) => {
                          if (v.target.checked) {
                            step.condition.referral.id
                              ? setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {
                                      id: step.condition.referral.id,
                                      parameter: "referral_by",
                                      operator: "==",
                                      value: "true",
                                    },
                                  },
                                })
                              : setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {
                                      parameter: "referral_by",
                                      operator: "==",
                                      value: "true",
                                    },
                                  },
                                });
                          } else {
                            step.condition.referral.id
                              ? setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {
                                      id: step.condition.referral.id,

                                      value: "optional",
                                    },
                                  },
                                })
                              : setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {},
                                  },
                                });
                          }
                        }}
                      >
                        Referral is required.
                      </Checkbox>
                      <Checkbox
                        checked={step.condition.referral.value == "false"}
                        onChange={(v) => {
                          if (v.target.checked) {
                            step.condition.referral.id
                              ? setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {
                                      id: step.condition.referral.id,
                                      parameter: "referral_by",
                                      operator: "==",
                                      value: "false",
                                    },
                                  },
                                })
                              : setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {
                                      parameter: "referral_by",
                                      operator: "==",
                                      value: "false",
                                    },
                                  },
                                });
                          } else {
                            step.condition.referral.id
                              ? setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {
                                      id: step.condition.referral.id,

                                      value: "optional",
                                    },
                                  },
                                })
                              : setStep({
                                  ...step,
                                  condition: {
                                    ...step.condition,
                                    referral: {},
                                  },
                                });
                          }
                        }}
                      >
                        Referral is not required.
                      </Checkbox>
                    </FormItem>
                  </Form>
                </>
              )}
            </div>
          </Modal>
        </>
      ) : isOpen2 ? (
        <ServiceView
          service={newService}
          setIsOpen={setIsOpen2}
          setService={setNewService}
          loading={isLoading}
          setLoading={setIsLoading}
          events={event}
        />
      ) : (
        <div className={`${isMobile ? "col-11 d-flex flex-column mx-2 " : ""}`}>
          <div
            className={`service d-flex flex-column gap-3  px-2 w-100 ${
              isMobile && "my-5"
            }`}
          >
            <span
              style={
                isMobile
                  ? { fontSize: "18px", fontWeight: "700", width: "15%" }
                  : { fontSize: "24px", fontWeight: "700", width: "15%" }
              }
            >
              Services
            </span>
            <Table
              scroll={{ x: "auto" }}
              loading={isLoading}
              columns={columns}
              dataSource={services.filter((s) => {
                return !s.deletedAt;
              })}
              expandedRowKeys={expandedRowKeys}
              expandable={{
                // expandedRowKeys={expandedRowKeys},
                onExpand: async (expanded, record) => {
                  setIsLoading(true);
                  var keys = [];
                  if (expanded) {
                    keys.push(record.id);
                  }

                  setexpandedRowKeys(keys);

                  if (expanded == true) {
                    await fetchServiceHistory(1, true, record.id);
                  }
                  setIsLoading(false);
                },
                expandedRowRender: (record) => (
                  <Table
                    scroll={{ x: "auto" }}
                    columns={History_columns}
                    dataSource={ServiceHistory}
                    size="small"
                    pagination={{
                      onChange: async (page) => {
                        await fetchServiceHistory(page);
                      },
                      pageSize: 15,
                    }}
                  />
                ),
              }}
              size="small"
              style={{ width: "90%" }}
              pagination={{
                onChange: async (page) => {
                  await fetchServices(page);
                },
                pageSize: 15,
              }}
              footer={() => {
                if (privilage.indexOf(access.serviceManegment.add) > -1)
                  return (
                    <div
                      onClick={() => {
                        setAddService(true);
                      }}
                      className="d-flex fle-row gap-2 px-4 align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <PlusOutlined
                        style={{
                          color: "rgba(46, 108, 223, 1)",
                        }}
                      />
                      <h6
                        style={{
                          color: "rgba(46, 108, 223, 1)",
                          marginBottom: "0",
                        }}
                      >
                        Add Service
                      </h6>
                    </div>
                  );
              }}
            />
            <div
              className="d-flex flex-row"
              style={
                isMobile
                  ? {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "18px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
                  : {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "24px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
              }
            >
              {" "}
              <span
                style={
                  isMobile
                    ? {
                        fontSize: "18px",
                        fontWeight: "700",
                        marginRight: "15px",
                      }
                    : {
                        fontSize: "24px",
                        fontWeight: "700",
                        marginRight: "15px",
                      }
                }
              >
                Removed Services
              </span>
              {!showRemove ? (
                <DownOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(true);
                  }}
                />
              ) : (
                <UpOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(false);
                  }}
                />
              )}
            </div>
            {showRemove && (
              <Table
                scroll={{ x: "auto" }}
                loading={isLoading}
                columns={deletedColumns
                  .filter((a) => {
                    return a.key != "x";
                  })
                  .concat([{ width: "30%" }])}
                dataSource={removedServices}
                size="small"
                pagination={{
                  onChange: async (page) => {
                    await fetchDeletedServices(page);
                  },
                  pageSize: 15,
                }}
                style={{ width: "90%" }}
              />
            )}
          </div>
        </div>
      )}
      <Modal
        open={addService}
        onCancel={() => {
          setNewService({
            steps: [],
            price: [],
            isEdit: true,
          });
          setT(1);
          IForm.resetFields();
          setAddService(false);
        }}
        footer={null}
      >
        <div className="condition d-flex flex-column px-3 gap-3 py-3 w-100 align-items-center">
          {t == 1 && (
            <>
              <h2 style={{ textAlign: "center" }}>
                Would you like to add a new service?
              </h2>
              <div className="d-flex flex-row gap-3 w-100 justify-content-center">
                <Button
                  onClick={() => {
                    // setAddService(false);
                    // setIsOpen(true);
                    setT(2);
                  }}
                  type="default"
                  style={{ overflowX: "hidden" }}
                  className="col-lg-5 col-6"
                >
                  {" "}
                  Add from existing services
                </Button>
                <Button
                  onClick={() => {
                    setAddService(false);
                    setIsOpen(true);
                  }}
                  type="primary"
                  className="col-lg-5 col-6"
                >
                  {" "}
                  Yes
                </Button>
              </div>
            </>
          )}

          {t == 2 && (
            <>
              <h2>Add from an existing service</h2>
              <div className="d-flex flex-column gap-3 w-75 align-items-center">
                <Form form={IForm} className="col-10">
                  <FormItem className="col-12">
                    <Select
                      className="col-12"
                      onChange={(v) => {
                        setNewService({
                          ...services.filter((s) => {
                            return s.id == v;
                          })[0],
                          cover: null,
                        });
                      }}
                    >
                      {services
                        .filter((s) => {
                          return !s.deletedAt;
                        })
                        .map((s) => {
                          return (
                            <Select.Option value={s.id}>
                              {s.title}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </FormItem>
                </Form>

                <Button
                  onClick={async () => {
                    setIsLoading(true);
                    var res2 = await postData(
                      `admin/service/${newService.id}/duplicate`
                    );
                    if (res2.status == 201) {
                      var res = await fetchData(
                        `admin/service/${res2.data.data.id}`
                      );
                      if (res.status == 200) {
                        var temp = res.data.data;
                        delete temp.id;
                        temp.cover = null;
                        temp.steps.map((s, i) => {
                          delete temp.steps[i].id;
                          temp.steps[i] = { ...temp.steps[i], isEdit: true };
                        });
                        setT(1);
                        setNewService({ ...temp, isEdit: true });
                        setAddService(false);
                        setIsOpen(true);
                        IForm.resetFields();
                      } else {
                        Modal.error({ title: res });
                      }
                    } else {
                      Modal.error({ title: res });
                    }
                    setIsLoading(false);
                  }}
                  loading={isLoading}
                  type="primary"
                  className="col-lg-4 col-6"
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ServiceManagement;
