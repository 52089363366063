import { useEffect, useState } from "react";
import { fetchData, deleteData, putData } from "../../endpoints";
import { useAssets, useMobile } from "../../hooks/index";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Spin } from "antd";
import AddPatient from "../AddPatient";
import { Modal } from "antd";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const Detile = ({
  patient,
  setPatient,
  getPatient,
  loadingPage,
  serviceTypes,
  domain,
  isEdit,
}) => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editStep, seteditstep] = useState(2);
  const getUser = async () => {
    setLoading(true);
    var res = await fetchData(`admin/user/${patient.userId}`);
    if (res.status == 200) {
      setUser(res.data.data);
    }
    setLoading(false);
  };
  const { confirm } = Modal;

  const showDeleteConfirm = async () => {
    confirm({
      title: "Are you sure you want to disable this patient?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removePatient();
      },
      onCancel() {},
    });
  };
  const removePatient = async () => {
    setLoading(true);
    var res = await deleteData(`admin/patient/${patient.id}`);
    if (res.status === 200) {
      setLoading(false);
      Modal.success({ title: "Patient have been successfully deleted." });
    } else {
      setLoading(false);
      Modal.error({ title: "There was a problem, please try again." });
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  const verifyPatient = async (id) => {
    setLoading(true);
    var res = await putData(`admin/patient/${id}/verify`);
    if (res.status === 200) {
      Modal.success({ title: res.data.message });
      getPatient(1);
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };

  return (
    <div
      className="d-flex gap-3 w-100"
      style={isMobile ? { flexDirection: "column" } : { flexDirection: "row" }}
      dir="ltr"
    >
      <div className="detile col-12 col-lg-8 d-flex flex-column py-3 gap-3">
        {loadingPage && (
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )}
        {!loadingPage && (
          <>
            <div>
              <table>
                <thead>
                  <th
                    colSpan={2}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Personal Information
                    {privilage.indexOf(
                      access.patientManegment.editPatient.personal
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(2);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>

                <tbody className="personalTable">
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      First Name: {" " + patient.firstName}
                    </td>
                    <td>Last Name: {" " + patient.lastName}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      Date of Birth:
                      {patient.birthday
                        ? " " +
                          patient.birthday.getFullYear() +
                          "/" +
                          patient.birthday.getMonth() +
                          "/" +
                          patient.birthday.getDate()
                        : " "}
                    </td>
                    <td>
                      Sex:{" " + patient.sex}
                      {patient.sex == "Other"
                        ? "/ " + patient.sexExtraInfo
                        : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      Gender: {" " + patient.gender}
                      {patient.gender == "Other"
                        ? "/ " + patient.genderExtraInfo
                        : ""}
                    </td>
                    <td>Relationship: {" " + patient.relationship} </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      Care Card:{" "}
                      {" " +
                        insertBlankAfterEveryThreeCharacters(patient.careCard)}
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table>
                <thead>
                  <th
                    colSpan={3}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Emergency Contact{" "}
                    {privilage.indexOf(
                      access.patientManegment.editPatient.emerjency
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(4);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Name</td>
                    <td style={{ fontWeight: "bold" }}>phone Number</td>
                    <td style={{ fontWeight: "bold" }}> Relationship</td>
                  </tr>
                  {patient.emergencyContacts.map((e) => {
                    return (
                      <tr>
                        <td>{e.fullName ? e.fullName : "No Name Set"}</td>
                        <td>{e.phoneNumber}</td>
                        <td>{e.relationship}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div>
              <table>
                <thead>
                  <th
                    colSpan={3}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Teacher/Care Provider Email Address{" "}
                    {privilage.indexOf(
                      access.patientManegment.editPatient.teacher
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(5);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>First Name</td>
                    <td style={{ fontWeight: "bold" }}>Last Name</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                  </tr>
                  {patient.teachers.map((t) => {
                    return (
                      <tr>
                        <td>{t.firstName}</td>
                        <td>{t.lastName}</td>
                        <td>{t.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <thead>
                  <th
                    colSpan={4}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Referral Information
                    {privilage.indexOf(
                      access.patientManegment.editPatient.refferral
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(6);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>First Name</td>
                    <td style={{ fontWeight: "bold" }}>Last Name</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                    <td style={{ fontWeight: "bold" }}>Fax</td>
                  </tr>

                  {patient.referralBy && (
                    <tr>
                      <td>{patient.referralBy.firstName}</td>
                      <td>{patient.referralBy.lastName}</td>
                      <td>{patient.referralBy.email}</td>
                      <td>{patient.referralBy.fax}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <thead>
                  <th
                    colSpan={1}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Services
                    {privilage.indexOf(
                      access.patientManegment.editPatient.serviceType
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(7);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  {patient.serviceTypes.map((s) => {
                    return (
                      <tr>
                        <td>{s.serviceType.title}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <div
        className={
          isMobile
            ? "col-lg-4 col-12 text-align-left my-4 d-flex justify-content-center"
            : "col-lg-4 col-12 text-align-left my-4"
        }
      >
        <div className="user-box px-3 py-3">
          <span style={{ fontWeight: "700", fontSize: "20px" }}>
            Main User:
          </span>
          <div className="d-flex flex-row gap-2 w-100 my-4" dir="ltr">
            <img
              src={patient.photo ? domain + patient.photo : getFile("Profile")}
              width={isMobile ? "40px" : "70px"}
              height={isMobile ? "40px" : "70px"}
              style={{ borderRadius: "30px" }}
            />
            <div
              className="d-flex flex-column"
              style={{
                justifyContent: "center",
                width: "70%",
                textAlign: "center",
              }}
            >
              {loading ? (
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              ) : (
                <>
                  <span style={{ fontWeight: "600", fontSize: "16px" }}>
                    {user.firstName + " " + user.lastName}
                  </span>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: "-10px",
              marginBottom: "20px",
            }}
            className="d-flex flex-column"
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Email: {user.email}
            </span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Phone Number: {user.phoneNumber}
            </span>
          </div>
          <div className="line" />
          <div className="d-flex flex-column gap-1">
            <h3>Date Added:</h3>
            <h4>{new Date(patient.createdAt).toDateString()}</h4>
            <br />
            <h3>MRN:</h3>
            <h4>
              {patient.verifiedAt
                ? patient.id
                : privilage.indexOf(access.patientManegment.verifyPatient) >
                    -1 && (
                    <strong
                      onClick={() => {
                        verifyPatient(patient.id);
                      }}
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                        cursor: "pointer",
                      }}
                    >
                      Verify
                    </strong>
                  )}
            </h4>
          </div>
          <div className="line" />
          {privilage.indexOf(access.patientManegment.disablePatient) > -1 && (
            <h2 style={{ cursor: "pointer" }} onClick={showDeleteConfirm}>
              Disable
            </h2>
          )}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={(null, null)}
        width={isMobile ? "90vw" : "55vw"}
        style={isMobile ? {} : { top: "5vh" }}
        destroyOnClose={true}
        onCancel={() => {
          // Modal.destroyAll()
          // seteditstep(2);
          // getPatient();
          setIsModalOpen(false);
        }}
      >
        <AddPatient
          isOpen={isModalOpen}
          setIsModalClose={setIsModalOpen}
          p={patient}
          userId={user.id}
          getPatients={getPatient}
          isEdit={true}
          serviceTypes={serviceTypes}
          editstep={editStep}
        />
      </Modal>
    </div>
  );
};
export default Detile;
