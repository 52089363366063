import CommentCard from "./commentCard";
import { Button, Spin } from "antd";
import access from "../../../assets/access";
import { fetchData } from "../../../endpoints";
import Cookies from "js-cookie";
import full from "../../../assets/fullAccess";
import { useMobile } from "../../../hooks";
import { useEffect, useState } from "react";
const privilage = Cookies.get("privilege") ?? full;
const SingleComment = ({
  open,
  comment,
  getComments,
  users,
  search,
  purchase,
  get,
}) => {
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(false);
  const getReplys = async () => {
    setLoading(true);
    var res = await fetchData(
      `admin/comment?perPage=10&parentId=${comment.id}&deletedAt=false&includes[]=watchers&includes[]=reporter&includes[]=assignees&includes[]=attachments`
    );
    if (res.status === 200) {
      setReplies(res.data.data);
    }
    setLoading(false);
  };
  const isMobile = useMobile();
  useEffect(() => {
    getReplys();
  }, [open, get]);
  return (
    <div className="d-flex flex-column gap-2 w-100 ">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center py-5">
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginLeft: "40px" }}>
          {replies.map((reply) => {
            return (
              <CommentCard
                purchase={purchase}
                comment={reply}
                getComments={() => {
                  getComments();
                  getReplys();
                }}
                Users={users}
                filter={search}
                noReply={true}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
export default SingleComment;
