import React, { useEffect } from "react";
import { AppRoutes } from "./routes";
import "./assets/styles/bootstrap.min.css";
import "./assets/styles/main.scss";
import { ConfigProvider } from "antd";

const App = () => {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.remove();
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove();
        }
      }
    });
  }, []);
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: "black",
          },
        },
        token: {
          colorPrimary: " rgba(46, 108, 223, 1)",
          fontFamily: "Lato",
        },
      }}
    >
      <div className="App">
        <AppRoutes />
      </div>
    </ConfigProvider>
  );
};

export default App;
