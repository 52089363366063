// import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Spin } from "antd";

export const CustomButton = ({
  uiType = "primary",
  additionalClassNames = "",
  href = "",
  link = "",
  blank = false,
  title,
  fullWidth = false,
  icon = "",
  onClick = () => {},
  loading = false,
  ...rest
}) => {
  const renderChildren = () => {
    if (href) {
      return (
        <a
          href={href}
          target={blank ? "_blank" : "_self"}
          rel="noreferrer"
          className={`custom-btn ${uiType} ${additionalClassNames} ${
            fullWidth ? "w-100" : ""
          }`}
          {...rest}
        >
          {icon ? (
            <div className="d-flex justify-content-center align-items-center">
              {icon}
              <span className="d-inline-block ms-2">{title}</span>
            </div>
          ) : loading ? (
            <Spin size="small">
              <div className="content" />
            </Spin>
          ) : (
            title
          )}
        </a>
      );
    } else if (link) {
      return (
        <Link
          to={link}
          className={`custom-btn ${uiType} ${additionalClassNames} ${
            fullWidth ? "w-100" : ""
          }`}
          {...rest}
        >
          {icon ? (
            <div className="d-flex justify-content-center align-items-center">
              {icon}
              <span className="d-inline-block ms-2">{title}</span>
            </div>
          ) : loading ? (
            <Spin size="small">
              <div className="content" />
            </Spin>
          ) : (
            title
          )}
        </Link>
      );
    } else {
      return (
        <button
          onClick={onClick}
          className={`custom-btn ${uiType} ${additionalClassNames} ${
            fullWidth ? "w-100" : ""
          }`}
          {...rest}
        >
          {icon ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spin size="small">
                <div className="content" />
              </Spin>
              {icon}
              <span className="d-inline-block ms-2">{title}</span>
            </div>
          ) : loading ? (
            <Spin size="small">
              <div className="content" />
            </Spin>
          ) : (
            title
          )}
        </button>
      );
    }
  };

  return renderChildren();
};
