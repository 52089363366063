import React from 'react';
import Player from './player.tsx';
import FilePicker from './file.tsx';
import {
  isAudio, readBlobURL, download, rename,
} from './utils.ts';
import { decodeAudioBuffer, sliceAudioBuffer } from './audio-helper.ts';
import encode from './worker-client.ts';
import './index.less';

import { useClassicState } from './hooks.ts';
import { SUpportedFormat } from './types.ts';
import { Button, Spin, message } from 'antd';
import './index.css'
import { async } from 'q';

 const Cropper = ({Save, setLoading})=> {
  const [state, setState] = useClassicState<{
    file: File | null;
    decoding: boolean;
    audioBuffer: AudioBuffer | null;
    paused: boolean;
    startTime: number;
    endTime: number;
    currentTime: number;
    processing: boolean;
  }>({
    file: null,
    decoding: false,
    audioBuffer: null,
    paused: true,
    startTime: 0,
    endTime: Infinity,
    currentTime: 0,
    processing: false,
  });

  const handleFileChange = async (file: File) => {
    if (!isAudio(file)) {
      message.error('it is not audio');
      return;
    }

    setState({
      file,
      paused: true,
      decoding: true,
      audioBuffer: null,
    });

    const audioBuffer = await decodeAudioBuffer(file);

    setState({
      paused: false,
      decoding: false,
      audioBuffer,
      startTime: 0,
      currentTime: 0,
      endTime: audioBuffer.duration / 2,
    });
  };

  const handleStartTimeChange = (time: number) => {
    setState({
      startTime: time,
    });
  };

  const handleEndTimeChange = (time: number) => {
    setState({
      endTime: time,
    });
  };

  const handleCurrentTimeChange = (time: number) => {
    setState({
      currentTime: time,
    });
  };

  const handleEnd = () => {
    setState({
      currentTime: state.startTime,
      paused: false,
    });
  };

  const handlePlayPauseClick = () => {
    setState({
      paused: !state.paused,
    });
  };

  const handleReplayClick = () => {
    setState({
      currentTime: state.startTime,
      paused: false,
    });
  };

  const handleEncode =async (type: SUpportedFormat) => {
   
    const {
      startTime, endTime, audioBuffer, file,
    } = state;
    if (!audioBuffer || !file) return;

    const { length, duration } = audioBuffer;

    const audioSliced = sliceAudioBuffer(
      audioBuffer,
      Math.floor(length * startTime / duration),
      Math.floor(length * endTime / duration),
    );

    setState({
      processing: true,
    });
    setLoading(true)
    await encode(audioSliced, type).then((myFile)=>
     { console.log(myFile)
      Save(myFile)}
    ).catch((e)=>{setLoading(false)
      console.log(e)})
    setLoading(false)
    setState({
      file: null,
    decoding: false,
    audioBuffer: null,
    paused: true,
    startTime: 0,
    endTime: Infinity,
    currentTime: 0,
    processing: false,
    });

  };

  const displaySeconds = (seconds: number) => `${seconds.toFixed(2)}s`;

  return (
    <div className="containerOfCrop">
      {
        state.audioBuffer || state.decoding ? (
          <div>
           

            {
              state.decoding ? (
                <div className="player player-landing">
                  DECODING...
                </div>
              ) : (
                <Player
                  audioBuffer={state.audioBuffer!}
                  blob={state.file!}
                  paused={state.paused}
                  startTime={state.startTime}
                  endTime={state.endTime}
                  currentTime={state.currentTime}
                  onStartTimeChange={handleStartTimeChange}
                  onEndTimeChange={handleEndTimeChange}
                  onCurrentTimeChange={handleCurrentTimeChange}
                  onEnd={handleEnd}
                />
              )
            }

            <div className="controllers">
              <FilePicker className="ctrl-item" onPick={handleFileChange}>
              <svg style={{width:'30px'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 6H3v2h12V6zm0 4H3v2h12v-2zM3 16h8v-2H3v2zM17 6v8.18c-.31-.11-.65-.18-1-.18-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3V8h3V6h-5z"/>
</svg>
              </FilePicker>

              <button
                type="button"
                className="ctrl-item"
                title="Play/Pause"
                onClick={handlePlayPauseClick}
              >
                {state.paused ? <svg style={{width:'30px'}} fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
</svg> : <svg style={{width:'30px'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z"/>
</svg>
} 
              </button>

              <button
                type="button"
                className="ctrl-item"
                title="Replay"
                onClick={handleReplayClick}
              >
               <svg style={{ width:'30px'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/>
</svg>
              </button>

              {/* <div className="dropdown list-wrap"> */}
                {/* <button
                  type="button"
                  className="ctrl-item"
                >
                  {state.processing ? <Spin/> : <svg style={{width:'30px'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
  <path d="M0 0h24v24H0z" fill="none"/>
</svg>}
                </button> */}
                {/* {
                  !state.processing && (
                    <ul className="list">
                       <li>
                        <button
                          type="button"
                          onClick={() => handleEncode('wav')}
                        >
                          Wav
                        </button>
                      </li>
                      {/* <li>
                        <button
                          type="button"
                          onClick={() => handleEncode('mp3')}
                          data-type="mp3"
                        >
                          MP3
                        </button>
                      </li> */}
                    {/* </ul> */}
                  
                {/* } */} 
              {/* </div> */}

              
            </div>
            {
                Number.isFinite(state.endTime)
                && (
                <span className="seconds">
                  Select
                  {' '}
                  <span className="seconds-range">
                    {
                    displaySeconds(state.endTime - state.startTime)
                  }
                  </span>
                  {' '}
                  of
                  {' '}
                  <span className="seconds-total">
                    {
                    displaySeconds(state.audioBuffer?.duration ?? 0)
                  }
                  </span>
                  {' '}
                  (from
                  {' '}
                  <span className="seconds-start">
                    {
                    displaySeconds(state.startTime)
                  }
                  </span>
                  {' '}
                  to
                  {' '}
                  <span className="seconds-end">
                    {
                    displaySeconds(state.endTime)
                  }
                  </span>
                  )
                </span>
                )
              }
              <Button type='primary' style={{marginLeft:'75%' , marginTop:'20px'}} onClick={() => handleEncode('mp3')}>
                Add to section 
              </Button>
          </div>
        
        ) : (
          <div className="landing">
           
            <FilePicker onPick={handleFileChange}>
              <div className="file-main">
              <svg style={{width:'30px'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 6H3v2h12V6zm0 4H3v2h12v-2zM3 16h8v-2H3v2zM17 6v8.18c-.31-.11-.65-.18-1-.18-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3V8h3V6h-5z"/>
</svg>
                Select music file
              </div>
            </FilePicker>
          </div>
        )
      }
    </div>
  );
}
export default Cropper