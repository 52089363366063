import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Tag,
  Space,
} from "antd";
import { useMobile } from "../../hooks";
import { fetchData, putData } from "../../endpoints";
import "./final.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 10, span: 12 },
};
function Final() {
  const [form] = Form.useForm();
  const isMobile = useMobile();
  const [donemodal, setdonemodal] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patients, setPatients] = useState([]);
  const [serviceTypesarray, setServiceTypes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [selected, setSelected] = useState({});
  const done = (u) =>
    Modal.confirm({
      title: "Are you sure you want to complete this purchase?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        doneWith(u);
      },
      onCancel() {},
    });
  const doneWith = async (u) => {
    setisLoading(true);
    var res = await putData(`admin/service-purchase/${u.id}/approve`);
    if (res.status != 200) {
      Modal.error({ title: res });
    }
    await getFinal(1, true);
    setisLoading(false);
  };
  async function getFinal(page = 1, reload = false) {
    //
    setisLoading(true);
    if (dataSource.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/service-purchase?status=done_by_patient&includes[]=patient&perPage=15&page=" +
          page
      );
      var temp1 = dataSource;
      if (reload) {
        temp1 = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          var temp = res.data.data.map((f) => {
            return { ...f, title: f.service.title };
          });
          setdataSource(
            temp1
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...temp])
          );
        } else {
          var temp = res.data.data.map((f) => {
            return { ...f, title: f.service.title };
          });
          setdataSource(
            temp1
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...temp, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
      //
    }
    setisLoading(false);
  }
  useEffect(() => {
    getFinal();
    if (privilage.indexOf(access.todoList.indexPatient) > -1) {
      getpatientmodal();
    }
  }, []);

  const columns = [
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              setSearchUserModal(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getFinal(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "patient",
      key: "patient",
      width: "30%",
      render: (u) =>
        u ? (
          <a
            style={{ color: "black" }}
            href={
              privilage.indexOf(access.patientManegment.showPatient) > -1 &&
              "/patients/" + u.id
            }
          >
            {u.firstName + " " + u.lastName}
          </a>
        ) : (
          <></>
        ),
    },
    {
      title: "Service",
      dataIndex: "title",
      key: "title",
      width: "30%",
    },
    privilage.indexOf(access.patientManegment.service.showpurchase) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          href={"/patients/" + u.patientId + "/services/in-progress/" + u.id}
          style={{ color: "black" }}
        >
          View
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.todoList.doneService) > -1 && (
          <a
            onClick={() => {
              setSelected(u);
              setdonemodal(true);
            }}
            style={{ color: "rgba(36, 140, 0, 1)" }}
          >
            Done By Admin
          </a>
        ),
    },
  ];
  const okPatientModal = async (id = null) => {
    setisLoading(true);
    var res;
    if (id) {
      res = await fetchData(
        `admin/service-purchase?status=done_by_patient&includes[]=patient&patientId=${id}`
      );
    } else
      res = await fetchData(
        `admin/service-purchase?status=done_by_patient&includes[]=patient&patientId=${data[selectedRowKeys].id}`
      );
    setdataSource(
      res.data.data.map((f) => {
        return { ...f, title: f.service.title };
      })
    );
    setSearchUserModal(false);
    setSelectedRowKeys(false);
    setisLoading(false);
  };
  const getColumnSearchPropsmodal = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearchmodal(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchmodal(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetmodal(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okPatientModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsmodal("name"),
      render: (record) => (
        <h2 style={{ cursor: "pointer" }} className="name-table">
          {record}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          <span style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}>
            Not Yet Verified
          </span>
        ),
      // getColumnSearchProps("clinicalCode"),
    },
  ];

  const handleSearchmodal = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getpatientmodal();
    } else {
      let search = "";
      if (dataIndex == "name") search = "firstName";
      else search = "id";
      var res = await fetchData(
        `admin/patient/?verifiedAt=true&deletedAt=false&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        setPatients(
          res.data.data.map((temp) => {
            return { ...temp, name: temp.firstName + " " + temp.lastName };
          })
        );
      }
    }
    setisLoading(false);
  };
  const handleResetmodal = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getpatientmodal(1, true);
  };
  const getpatientmodal = async (page = 1, reload = false) => {
    setisLoading(true);
    var temp = patients;
    if (reload) {
      temp = [];
    }
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&verifiedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setisLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",
                  // textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "70px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "40px 0px",
                    fontSize: "24px",
                  }
            }
          >
            <strong>Final Approval</strong>
          </div>
          <div style={isMobile ? { minWidth: "80vw" } : {}}>
            <Table
              scroll={{ x: "auto" }}
              columns={columns}
              dataSource={dataSource}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={donemodal}
        // onOk={handleOk}
        footer={null}
        onCancel={() => setdonemodal(false)}
      >
        <div style={{ textAlign: "center", fontSize: "36px" }}>
          <strong>Done By Admin</strong>
        </div>
        <div style={{ textAlign: "center" }}>
          Are you sure you want to change the status of this service? The
          patient will no longer have access to this service after the change.
        </div>
        <hr />
        <p style={{ textAlign: "center" }}>send report</p>

        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={(values) => {
            setdonemodal(false);
            done(selected);
          }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: false }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: false }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="Send" rules={[{ required: false }]}>
            <Checkbox.Group>
              <Row>
                <Checkbox value="Send">Send Survey Questionnaire</Checkbox>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="Access" rules={[{ required: false }]}>
            <Checkbox.Group>
              <Row>
                <Checkbox value="Access">
                  Give Access of Final Report to Patient
                </Checkbox>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        style={isMobile ? {} : { maxHeight: "80vh" }}
        open={SearchUserModal}
        title="Search Patient"
        width={1000}
        onOk={okPatientModal}
        onCancel={() => {
          setSearchUserModal(false);
        }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
    </>
  );
}
export default Final;
