import { React, useState } from "react";
import { Button, Form, Input } from "antd";
import { putData } from "../../endpoints";
import "./styles.scss";
import { useAssets } from "../../hooks/index";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";

const ChangPassword = () => {
  const { getFile } = useAssets();
  const { state } = useLocation();
  const { email } = state;
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (passwordConfirm !== password) {
      Swal.fire({
        title: "",
        text: "The password and confirmation password do not match.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (password.length < 8) {
      Swal.fire({
        title: "",
        text: "Password must be at least 8 characters",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }

    var data = {
      email,
      password: password,
      password_confirmation: passwordConfirm,
    };

    var res = await putData("user", JSON.stringify(data));
    if (res.status != 200) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    } else {
      Swal.fire({
        title: "",
        text: "Your password has been successfully changed",
        icon: "success",
        confirmButtonText: "ok",
      });
      navigator("/sign-in");
    }
    setIsLoading(false);
  };

  return (
    <div className="background">
      <img
        src={getFile("LightLogo")}
        style={{
          position: "absolute",
          top: "35px",
          left: "35px",
          maxWidth: "20vw",
        }}
      />
      <div className="chang-box">
        <h1>Change Password</h1>
        <Form
          name="chang-pass"
          layout={"vertical"}
          style={{ maxWidth: 900, width: "80%" }}
          autoComplete="off"
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please enter your New Password!" },
            ]}
          >
            <Input.Password
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            name="confirm-password"
            rules={[
              {
                required: true,
                message: "Please enter your Confirm New Password!",
              },
            ]}
          >
            <Input.Password
              value={passwordConfirm}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default ChangPassword;
