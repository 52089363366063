import { React, useState } from "react";
import "./styles.scss";
import { Tabs, Table, Dropdown, Button } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

const FormPage = (
  setIsOpen,
  form,
  setForm,
  isMobile,
  navMobileItem,
  setNavMobileItem
) => {
  const items = [
    {
      key: "1",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 0 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(0)}
        >
          Overview
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 1 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(1)}
        >
          Patient
        </button>
      ),
    },
  ];
  return (
    <div
      style={
        !isMobile
          ? { backgroundColor: "white", minHeight: "100vh" }
          : {
              backgroundColor: "white",
              minHeight: "100vh",
              paddingRight: "5%",
              paddingLeft: "5%",
            }
      }
      className="d-flex flex-column w-100"
    >
      <svg
        style={
          isMobile
            ? { marginTop: "40px", marginLeft: "0.2rem" }
            : { marginTop: "15px", marginLeft: "15px" }
        }
        onClick={() => {
          setIsOpen(false);
          setForm({});
        }}
        width={isMobile ? "20px" : "24px"}
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7996 8.39557H2.65334L10.0208 1.01421C10.2528 0.78219 10.2528 0.40603 10.0208 0.174013C9.7889 -0.0580043 9.41285 -0.0580043 9.18091 0.174013L0.781469 8.57595C0.665495 8.68473 0.599609 8.83691 0.599609 8.99605C0.599609 9.15519 0.665498 9.30737 0.781469 9.41615L9.18091 17.8181C9.28965 17.9341 9.44178 18 9.60088 18C9.75997 18 9.91211 17.9341 10.0208 17.8181C10.1368 17.7093 10.2027 17.5571 10.2027 17.398C10.2027 17.2388 10.1368 17.0867 10.0208 16.9779L2.65334 9.59653H22.7996C23.131 9.59653 23.3996 9.32781 23.3996 8.99639C23.3996 8.66498 23.131 8.39625 22.7996 8.39625V8.39557Z"
          fill="#18324E"
        />
      </svg>
      <div
        // style={{ margin:'0% 5%',paddingLeft: "20px",alignItems:'center' }}
        style={
          isMobile
            ? {
                paddingLeft: "15px",
                alignItems: "center",
                marginTop: "-60px",
                marginBottom: "-30px",
              }
            : { margin: "0% 5%", paddingLeft: "20px", alignItems: "center" }
        }
        className="d-flex flex-row gap-5"
      >
        <div
          className={`d-flex flex-column ${isMobile && "col-6"}`}
          style={{ alignItems: "center" }}
        >
          <span
            style={
              isMobile
                ? { fontWeight: "600", fontSize: "14px" }
                : { fontWeight: "700", fontSize: "20px" }
            }
          >
            {form.title ?? ""}
          </span>
        </div>
        {isMobile && (
          <Dropdown menu={{ items }} placement="bottomLeft">
            <button
              className="menu-btn-mobile col-1"
              style={{ marginBottom: "35px" }}
            >
              <AppstoreFilled style={{ fontSize: "20px" }} />
            </button>
          </Dropdown>
        )}
      </div>{" "}
      {isMobile && <hr></hr>}
      <div
        style={
          isMobile
            ? { width: "93%", marginTop: "20px" }
            : { width: "93%", marginTop: "-20px" }
        }
        className="top-tabs d-flex flex-row mx-4 "
        dir="rtl"
      >
        {!isMobile && (
          <Tabs
            size={isMobile ? "small" : "large"}
            tabBarGutter={18}
            style={{ color: "black", fontWeight: "500", width: "100%" }}
            defaultActiveKey={1}
            tabPosition="top"
            direction="rtl"
            type="card"
            items={[
              {
                label: `Patients`,
                key: 0,
                children: Patients(form, isMobile),
              },
              {
                label: `Overview`,
                key: 1,
                children: Overview(form),
              },
            ]}
          />
        )}
      </div>
      <div>
        {isMobile && (
          <div>
            {navMobileItem === 0 && Overview(form)}
            {navMobileItem === 1 && Patients(form, isMobile)}
          </div>
        )}
      </div>
    </div>
  );
};

const Overview = (form) => {
  return (
    <div dir="ltr" className="detile d-flex flex-column gap-5 py-3">
      <div>
        <table>
          <thead>
            <th colSpan={2}>Form Information</th>
          </thead>

          <tbody className="personalTable">
            <tr>
              <td
                style={{
                  borderRight: "1px solid rgba(242, 242, 242, 1)",
                }}
              >
                Title: {" " + form.title}
              </td>
              <td>
                Code: <br /> {" " + form.code}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid rgba(242, 242, 242, 1)",
                }}
              >
                Parent ID:
                <br />
                {form.parentId}
              </td>
              <td>Type: {" " + form.visibility} </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid rgba(242, 242, 242, 1)",
                }}
              >
                Status: {" " + form.status}
              </td>
              <td>Priority: {" " + form.priority} </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid rgba(242, 242, 242, 1)",
                }}
              >
                Created at: {new Date(form.createdAt).toDateString()}
              </td>
              <td>Updated at: {new Date(form.updatedAt).toDateString()} </td>
            </tr>
            {form.description && (
              <tr>
                <td
                  style={{
                    borderRight: "1px solid rgba(242, 242, 242, 1)",
                  }}
                >
                  Description:
                  <br /> {" " + form.description}
                </td>
                <td />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="form-card" id={form.code} style={{ height: "80px" }}>
        <div
          className="form-card__title d-flex flex-row gap-2 px-4"
          style={{ justifyContent: "flex-start", width: "75%" }}
        >
          <h6 style={{ textAlign: "left" }}>{form.title}</h6>
        </div>
        <div
          className="d-flex flex-row gap-3 w-50"
          style={{ textAlign: "right" }}
        >
          <a
            style={{ color: "rgba(46, 108, 223, 1)", textAlign: "right" }}
            onClick={() => {}}
            target="_blank"
          >
            Go to JotForm
          </a>
        </div>
      </div>
    </div>
  );
};
const Patients = (form, isMobile) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "35%",
    },
    {
      title: "MRN",
      dataIndex: "clinicalCode",
      key: "clinicalCode",
      width: "20%",
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      width: "35%",
      render: (serviceTypes) => {
        <span>
          {" "}
          {serviceTypes.map((s) => {
            return (
              <li color={"geekblue"} key={s}>
                {s}
              </li>
            );
          })}
        </span>;
      },
    },
  ];
  const data = form.patient.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: p.createdAt.substr(0, 10),
    };
  });

  return (
    <div
      className={
        isMobile
          ? "px-1 py-3 d-flex flex-column gap-3"
          : "px-5 py-5 d-flex flex-column gap-3"
      }
      style={isMobile ? { width: "100%" } : { width: "70vw" }}
      dir="ltr"
    >
      <div
        className="d-flex flex-row gap-3 w-100"
        style={{
          justifyContent: "flex-end",
          fontWeight: "bold",
        }}
      ></div>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default FormPage;
