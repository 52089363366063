import { React, useState } from "react";
import { Button, Form, Input } from "antd";
import "./styles.scss";
import { useAssets, useMobile } from "../../hooks/index";
import { useNavigate } from "react-router-dom";
import { postWithoutToket } from "../../endpoints";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Authentication from "../Authentication";

const ForgotPassword = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [input, setinput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [secret, setSecret] = useState(0);
  const navigator = useNavigate();
  const EmailValidate = (email) => {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    if (EmailValidate(input)) {
      var body = { email: input, password: "" };
      var res = await postWithoutToket("auth/login", JSON.stringify(body));
      if (res.status != 201) {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      } else {
        if (!res.data.data.newUser) {
          // navigator("/auth");
          Cookies.set("email", input);
          setSecret(res.data.data.secret);
          setStep(2);
        } else {
          Swal.fire({
            title: "You do not have an account!",
            text: "Please register first",
            icon: "error",
            confirmButtonText: "ok",
          });
          navigator("/sign-in");
        }
      }
    } else {
      Swal.fire({
        title: "Invalid Email!",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  };
  if (step === 1) {
    return (
      <div className="background">
        {isMobile ? (
          <img
            src={getFile("Logo")}
            style={{
              position: "absolute",
              top: "35px",
              left: "35px",
              maxWidth: "50vw",
              zIndex: "3",
            }}
          />
        ) : (
          <img
            src={getFile("LightLogo")}
            style={{
              position: "absolute",
              top: "35px",
              left: "35px",
              maxWidth: "20vw",
            }}
          />
        )}
        <div className="forgot-box px-4">
          <h1>ForgotPassword</h1>
          <h2>Please enter the Email you have created an account with</h2>
          <Form
            size="large"
            name="forgot-password"
            layout={"vertical"}
            style={{ maxWidth: 900, width: "80%" }}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please enter your email!" }]}
            >
              <Input
                placeholder="Enter your Email"
                value={input}
                onChange={(e) => setinput(e.target.value)}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 4, offset: !isMobile ? 10 : 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                onClick={handleSubmit}
                loading={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  } else {
    return <Authentication Secret={secret} />;
  }
};
export default ForgotPassword;
