import "./styles.scss";
import {
  Button,
  Input,
  Modal,
  Form,
  Checkbox,
  InputNumber,
  Select,
  Switch,
  Radio,
  Spin,
} from "antd";

import {
  postData,
  deleteData,
  putData,
  putFormData,
  fetchData,
} from "../../endpoints";
import { useMobile } from "../../hooks/useMobile";
import { ExclamationCircleFilled } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const AddService = ({
  newService,
  setNewService,
  setIsOpen,
  serviceTypes,
  isLoading,
  event,
  setIsLoading,
  fetchServices,
  setAddCond,
  setStep,
  loading2,
  setLoading2,
  loading3,
  setLoading3,
}) => {
  // console.log(newService)
  const [myForm] = Form.useForm();
  const isMobile = useMobile();
  const { confirm } = Modal;
  const [html, setHtml] = useState(newService.description);
  const convertStringToHTML = (htmlString) => {
    let element = document.getElementById("myElement");
    if (element) element.innerHTML = htmlString;
  };

  const [imageUrl, setImageUrl] = useState(
    newService.cover ? (
      <img
        style={{ marginLeft: "26%", width: "30%", height: "auto" }}
        src={`https://allbrains-dashboard-stg-public.s3.amazonaws.com/${
          newService.cover
        }?${new Date().getMilliseconds}`}
      />
    ) : null
  );

  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  var formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  function onChange(e) {
    convertStringToHTML(e);
    setHtml(e);
    setNewService({ ...newService, description: e });
  }

  const publishService = async (id) => {
    setIsLoading(true);
    var res = await putData(`admin/service/${id}/publish`);
    if (res.status == 200) {
      myForm.resetFields();
      setNewService({
        steps: [],
        price: [],
        isEdit: true,
      });
      fetchServices();
      setIsOpen(false);
      setIsLoading(false);
      Modal.success({ title: res.data.message });
    } else {
      setIsLoading(false);
      Modal.error({ title: res });
    }
  };
  const postStep = async (step, i) => {
    setIsLoading(true);
    var temp2 = step;
    var temp1 = { ...temp2, serviceId: newService.id };
    if (
      step.type == "information" ||
      step.type == "payment" ||
      step.type == "document"
    ) {
      temp1 = { ...temp1, modelId: null };
    }

    var res = await postData("admin/service-step", temp1);
    if (res.status == 201) {
      var temp = newService.steps;
      temp[i] = { ...res.data.data, isEdit: false, blocks: step.blocks };
      setNewService({ ...newService, steps: temp });
      Modal.success({ title: res.data.message });
      setIsLoading(false);
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const editService = async () => {
    setIsLoading(true);
    var res = await putData("admin/service/" + newService.id, newService);
    if (res.status == 200) {
      if (typeof newService.cover != "string") {
        // alert("meoooooo")
        // console.log("meooooooooo")
        serviceCover(newService.id);
      }

      Modal.success({ title: res.data.message });
      setNewService({
        ...res.data.data,
        price: newService.price,
        steps: newService.steps,
        isEdit: false,
      });
      setIsLoading(false);
      return true;
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
      return false;
    }
  };
  const editStep = async (step, i) => {
    setIsLoading(true);
    var temp1 = step;
    if (
      step.type == "information" ||
      step.type == "payment" ||
      step.type == "document"
    ) {
      temp1 = { ...temp1, modelId: null };
    }
    var res = await putData("admin/service-step/" + step.id, {
      ...temp1,
      serviceId: newService.id,
    });
    if (res.status == 200) {
      var temp = newService.steps;
      temp[i] = { ...res.data.data, isEdit: false, blocks: temp[i].blocks };
      setNewService({ ...newService, steps: temp });
      Modal.success({ title: res.data.message });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const addService = async () => {
    setIsLoading(true);

    var res = await postData("admin/service", { ...newService });
    if (res.status == 201) {
      if (newService.cover) {
        serviceCover(res.data.data.id);
      }
      Modal.success({ title: res.data.message });
      if (newService.steps.length > 0) {
        setNewService({ ...newService, id: res.data.data.id, isEdit: false });
      } else {
        setNewService({
          ...res.data.data,
          price: [],
          steps: [],
          isEdit: false,
        });
      }
      setIsLoading(false);
      return true;
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
      return false;
    }
  };

  const serviceCover = async (id) => {
    // console.log(typeof newService.cover);
    const formData = new FormData();
    // console.log(newService.cover)
    formData.append("file", newService.cover);
    var res2 = await putFormData(
      "admin/service/" + id + "/cover/upload",
      formData
    );
    if (res2.status == 200) {
      console.log(res2);
      // setNewService({ ...newService, cover: res2.data.data.cover });
      setImageUrl(
        <div>
          <img
            style={{ marginLeft: "26%", width: "30%", height: "auto" }}
            src={`https://allbrains-dashboard-stg-public.s3.amazonaws.com/${
              res2.data.data.cover
            }?${new Date().getMilliseconds}`}
          />
        </div>
      );
    }
    if (res2.status != 200) {
      Modal.error({ title: res2 });
    }

    // console.log(newService.cover);
  };

  const deleteStep = async (step) => {
    setIsLoading(true);
    if (step.id) {
      var res = await deleteData(`admin/service-step/${step.id}`);
      if (res.status == 200) {
        var temp = newService.steps.filter((a) => {
          return a != step;
        });
        setNewService({ ...newService, steps: temp });
        setIsLoading(false);
        Modal.success({ title: res.data.message });
      } else {
        setIsLoading(false);
        Modal.error({ title: res });
      }
    } else {
      temp = newService.steps.filter((a) => {
        return a != step;
      });
      setNewService({ ...newService, steps: temp });
      setIsLoading(false);
      Modal.success({ title: "Service step deleted successfully." });
    }
    setIsLoading(false);
  };

  const showDeleteConfirm = async (step) => {
    confirm({
      title: "Are you sure remove this step?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteStep(step);
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div
        className={`add-service d-flex flex-column gap-1 ${
          isMobile ? "px-3" : "px-5"
        } `}
      >
        {convertStringToHTML(html)}
        <svg
          onClick={() => {
            setIsOpen(false);
            setNewService({ steps: [], isEdit: true });
            myForm.resetFields();
          }}
          style={{ marginBottom: "30px" }}
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.8016 8.39557H2.6553L10.0228 1.01421C10.2548 0.78219 10.2548 0.40603 10.0228 0.174013C9.79085 -0.0580043 9.41481 -0.0580043 9.18286 0.174013L0.783422 8.57595C0.667448 8.68473 0.601562 8.83691 0.601562 8.99605C0.601562 9.15519 0.667451 9.30737 0.783422 9.41615L9.18286 17.8181C9.2916 17.9341 9.44374 18 9.60283 18C9.76193 18 9.91406 17.9341 10.0228 17.8181C10.1388 17.7093 10.2047 17.5571 10.2047 17.398C10.2047 17.2388 10.1388 17.0867 10.0228 16.9779L2.6553 9.59653H22.8016C23.1329 9.59653 23.4016 9.32781 23.4016 8.99639C23.4016 8.66498 23.1329 8.39625 22.8016 8.39625V8.39557Z"
            fill="#18324E"
          />
        </svg>
        {newService.editService ? (
          <h2>Edit Service</h2>
        ) : (
          <h2> Add New Service</h2>
        )}
        <Form
          className="d-flex flex-column gap-3 py-3"
          labelCol={{ span: 6 }}
          size="large"
          form={myForm}
        >
          <div>
            <h3>Information</h3>
            <div className="h-line" />
            <div
              className={`col-12 d-flex ${
                isMobile ? "flex-column" : "flex-row gap-4"
              }`}
            >
              <div className="col-lg-6 col-12 px-2">
                <Form.Item
                  label={"Title"}
                  name="title"
                  rules={[{ required: true, message: "Please enter a title!" }]}
                >
                  <Input
                    defaultValue={newService.title}
                    // value={newService.title}
                    disabled={!newService.isEdit}
                    onChange={(e) => {
                      setNewService({ ...newService, title: e.target.value });
                    }}
                  />
                </Form.Item>

                <FormItem
                  label={"Types"}
                  name="types"
                  rules={[{ required: true, message: "Please add types!" }]}
                  initialValue={newService.types}
                >
                  <Checkbox.Group
                    disabled={!newService.isEdit}
                    options={serviceTypes.map((s) => {
                      return { value: s.id, label: s.title };
                    })}
                    className="d-flex flex-row gap-2"
                    onChange={(value) => {
                      setNewService({
                        ...newService,
                        types: value,
                      });
                    }}
                  ></Checkbox.Group>
                </FormItem>
              </div>
              <div className={`col-lg-5 col-12 ${isMobile ? "px-2" : ""}`}>
                <FormItem label={"Duration"} name={"duration"}>
                  <InputNumber
                    value={newService.activeDays}
                    onChange={(e) => {
                      setNewService({
                        ...newService,
                        activeDays: e,
                      });
                    }}
                    keyboard={true}
                    disabled={!newService.isEdit}
                  />
                  <span style={{ marginLeft: "8px" }}> days </span>
                </FormItem>

                <FormItem label={"Photo"}>
                  <FileUploader
                    disabled={!newService.isEdit}
                    required
                    label=" "
                    handleChange={(file) => {
                      setNewService({ ...newService, cover: file });
                      setImageUrl(null);
                    }}
                    name="file"
                    types={["JPG", "JPEG", "PNG"]}
                    maxSize={2}
                    children={
                      <Button className="col-12" type="default">
                        {!newService.cover
                          ? "Upload"
                          : typeof newService.cover == "string"
                          ? "Upload New Photo"
                          : "Uploaded Successfully!"}
                      </Button>
                    }
                  />
                </FormItem>
                {imageUrl ? imageUrl : <></>}
              </div>
            </div>
            <div
              className="col-10 gap-3 mb-4 px-2"
              style={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    color: "#ff4d4f",
                    fontSize: "22px",
                    marginRight: "3px",
                  }}
                >
                  *
                </div>
                <div>description:</div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                {newService.isEdit ? (
                  <ReactQuill
                    value={html}
                    formats={formats}
                    modules={modules}
                    onChange={onChange}
                  />
                ) : (
                  <div id="myElement" />
                )}
              </div>
            </div>
            <Button
              style={isMobile ? { marginLeft: "70%" } : { marginLeft: "80%" }}
              className="col-lg-2 col-3"
              type="primary"
              loading={isLoading && newService.isEdit}
              onClick={async () => {
                if (newService.isEdit && !newService.id) {
                  await addService();
                }
                if (newService.isEdit && newService.id) {
                  await editService();
                }
                if (!newService.isEdit) {
                  setNewService({ ...newService, isEdit: true });
                }
              }}
            >
              {newService.isEdit ? (!newService.id ? "Add" : "Save") : "Edit"}
            </Button>
          </div>
          {newService.id && (
            <div>
              <h3>Steps</h3>
              <div className="h-line" />

              {newService.steps ? (
                loading3 ? (
                  <Spin size="large" />
                ) : (
                  newService.steps.map((s, i) => {
                    return Step(
                      newService,
                      setNewService,
                      setStep,
                      setAddCond,
                      i,
                      event,
                      postStep,
                      editStep,
                      showDeleteConfirm,
                      isLoading,
                      loading2,
                      setLoading2,
                      isMobile,
                      setLoading3,
                      loading3
                    );
                  })
                )
              ) : null}

              {!loading3 &&
                privilage.indexOf(access.serviceManegment.addStep) > -1 && (
                  <svg
                    onClick={() => {
                      setNewService({
                        ...newService,
                        steps: [
                          ...newService.steps,
                          {
                            priority: newService.steps.length,
                            isEdit: true,
                          },
                        ],
                      });
                    }}
                    style={{ marginBottom: "30px" }}
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="4" fill="#2E6CDF" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.0302 14.9328H17.0671V4.96968C17.0671 4.37996 16.5891 3.90244 15.9999 3.90244C15.4102 3.90244 14.9327 4.37996 14.9327 4.96968V14.9328H4.96958C4.37987 14.9328 3.90234 15.4103 3.90234 16C3.90234 16.5897 4.37987 17.0672 4.96958 17.0672H14.9327V27.0303C14.9327 27.6195 15.4102 28.0976 15.9999 28.0976C16.5891 28.0976 17.0671 27.6195 17.0671 27.0303V17.0672H27.0302C27.6194 17.0672 28.0975 16.5897 28.0975 16C28.0975 15.4103 27.6194 14.9328 27.0302 14.9328Z"
                      fill="white"
                    />
                  </svg>
                )}
            </div>
          )}
        </Form>
        <div
          style={{ justifyContent: "right" }}
          className="d-flex flex-row gap-3 w-100"
        >
          {newService.steps.length > 0 &&
            newService.id &&
            privilage.indexOf(access.serviceManegment.publish) > -1 && (
              <Button
                style={
                  isMobile
                    ? { marginBottom: "20px", width: "33%" }
                    : {
                        marginBottom: "20px",
                        width: "15%",
                      }
                }
                loading={isLoading}
                onClick={async () => {
                  await publishService(newService.id);
                }}
                type="primary"
              >
                Publish
              </Button>
            )}
          {newService.id && (
            <Button
              style={
                isMobile
                  ? { marginBottom: "20px", width: "33%" }
                  : {
                      marginBottom: "20px",
                      width: "15%",
                    }
              }
              loading={isLoading}
              onClick={() => {
                myForm.resetFields();
                setNewService({
                  steps: [],
                  price: [],
                  isEdit: true,
                });
                fetchServices();
                setIsOpen(false);
              }}
              type="default"
            >
              Save Draft
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const Step = (
  newService,
  setNewService,
  setStep,
  setAddCond,
  i,
  event,
  postStep,
  editStep,
  deleteStep,
  isLoading,
  loading2,
  setLoading2,
  isMobile,
  setLoading3,
  loading3
) => {
  const getCondition = async (step, i) => {
    setLoading2(true);
    if (step.id) {
      var res = await fetchData(`admin/service-step/${step.id}/block`);
      var res2 = await fetchData(`admin/service-step/${step.id}/condition`);
      if (res.status == 200 && res2.status == 200) {
        var temp = newService.steps;
        var referral = res2.data.data.filter((a) => {
          return a.parameter == "referral_by";
        });
        if (referral.length == 0) {
          referral[0] = {};
        }
        var age = res2.data.data.filter((a) => {
          return a.parameter == "age";
        });
        temp[i] = {
          ...temp[i],
          blocks: res.data.data,
          condition: { referral: referral[0], age: age },
        };
        setNewService({ ...newService, steps: temp });
        setStep({
          ...step,
          blocks: res.data.data,
          condition: { referral: referral[0], age: age },
        });
      } else {
        setLoading2(false);
        Modal.error({ title: res });
        return false;
      }
    } else if (!step.blocks) {
      var temp = newService.steps;
      temp[i] = { ...temp[i], blocks: [] };
      setNewService({ ...newService, steps: temp });
      setStep({ ...step, blocks: res.data.data });
    }
    setLoading2(false);
    return true;
  };
  return (
    <>
      <div
        id={"step" + i}
        className={`d-flex ${isMobile ? "flex-column" : "flex-row gap-3"} `}
      >
        <div
          style={isMobile ? { width: "100%" } : { width: "5%" }}
          className={`d-flex flex-row`}
        >
          <div
            className={`d-flex ${
              isMobile ? "flex-row col-10 px-4" : "flex-column"
            } gap-4`}
          >
            {privilage.indexOf(access.serviceManegment.editStep) > -1 && (
              <svg
                style={{ marginTop: "7px" }}
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={async () => {
                  setLoading3(true);
                  var temp = newService.steps;
                  var mytemp = { ...temp[i], priority: i - 1 };
                  temp[i] = { ...temp[i - 1], priority: i };
                  temp[i - 1] = mytemp;
                  await setNewService({ ...newService, steps: temp });
                  if (temp[i].id) {
                    var res = await putData(
                      "admin/service-step/" + temp[i].id,
                      {
                        ...temp[i],
                        serviceId: newService.id,
                      }
                    );
                    if (res.status == 200 && temp[i - 1].id) {
                      var res2 = await putData(
                        "admin/service-step/" + temp[i - 1].id,
                        {
                          ...temp[i - 1],
                          serviceId: newService.id,
                        }
                      );
                      if (res2.status == 200) {
                        Modal.success({ title: res2.data.message });
                      }
                    }
                  }
                  setLoading3(false);
                }}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.22581 14.3279H10L7.09677 19L4.19355 14.3279H5.96774V6.54098H8.22581V14.3279ZM1.77419 4.67213H0L2.90323 0L5.80645 4.67213H4.03226V12.459H1.77419V4.67213Z"
                  fill="#4C4C4C"
                />
              </svg>
            )}
            {!isLoading &&
            privilage.indexOf(access.serviceManegment.delteStep) > -1 ? (
              <svg
                style={isMobile ? { marginTop: "7px" } : {}}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                onClick={async () => {
                  await deleteStep(newService.steps[i]);
                }}
              >
                <path
                  className="delete-icon"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.38462 3.3857C1.254 3.3857 1.12938 3.44742 1.04169 3.55542C0.954456 3.66342 0.912002 3.80793 0.925387 3.95296C0.925387 3.95296 1.56 10.7904 1.79446 13.316C1.86738 14.1024 2.46231 14.7 3.1717 14.7C4.59093 14.7 7.46262 14.7 8.88554 14.7C9.59862 14.7 10.1954 14.0962 10.2637 13.3052C10.4834 10.7744 11.0746 3.94936 11.0746 3.94936C11.0871 3.80536 11.0446 3.66136 10.9569 3.55439C10.8697 3.4469 10.7455 3.3857 10.6154 3.3857H1.38462ZM6.92308 6.47142V11.6143C6.92308 11.8982 7.12985 12.1286 7.38462 12.1286C7.63938 12.1286 7.84615 11.8982 7.84615 11.6143V6.47142C7.84615 6.18753 7.63938 5.95713 7.38462 5.95713C7.12985 5.95713 6.92308 6.18753 6.92308 6.47142ZM4.15385 6.47142V11.6143C4.15385 11.8982 4.36062 12.1286 4.61538 12.1286C4.87015 12.1286 5.07692 11.8982 5.07692 11.6143V6.47142C5.07692 6.18753 4.87015 5.95713 4.61538 5.95713C4.36062 5.95713 4.15385 6.18753 4.15385 6.47142ZM8.61553 1.84284L8.39354 1.24936C8.17846 0.674388 7.67446 0.299988 7.11553 0.299988C6.486 0.299988 5.514 0.299988 4.88447 0.299988C4.32554 0.299988 3.82154 0.674388 3.60646 1.24936L3.38447 1.84284H0.428763C0.191993 1.84284 0 2.07324 0 2.35713C0 2.64102 0.191993 2.87142 0.428763 2.87142H11.5712C11.808 2.87142 12 2.64102 12 2.35713C12 2.07324 11.808 1.84284 11.5712 1.84284H8.61553Z"
                  fill="#4C4C4C"
                />
              </svg>
            ) : (
              <Spin />
            )}
            {!newService.steps[i].isEdit &&
              privilage.indexOf(access.serviceManegment.editStep) > -1 && (
                <h4
                  style={{
                    color: "#2E6CDF",
                    textAlign: "center",
                    alignSelf: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    marginLeft: "-3px",
                    marginTop: "-5px",
                  }}
                  onClick={() => {
                    var temp = newService.steps;
                    temp[i] = { ...temp[i], isEdit: true };
                    setNewService({ ...newService, steps: temp });
                  }}
                >
                  Edit
                </h4>
              )}
          </div>

          <span
            style={{
              color: " rgba(46, 108, 223, 1)",
              fontSize: "16px",
              fontWeight: "700",
              marginTop: "7px",
              marginLeft: "15px",
            }}
          >
            {i + 1}
          </span>
        </div>
        <div
          style={isMobile ? { width: "100%" } : { width: "50%" }}
          className="px-2 "
        >
          <div className="d-flex flex-row gap-5 mb-4 align-items-center">
            <span style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Title:{" "}
            </span>

            <Input
              className="col-9"
              value={newService.steps[i].title}
              disabled={!newService.steps[i].isEdit}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = {
                  ...temp[i],
                  title: e.target.value,
                };
                setNewService({ ...newService, steps: temp });
              }}
            />
          </div>
          <div className="d-flex flex-row gap-5 mb-4 align-items-center">
            <span style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Type:{" "}
            </span>

            <Select
              className="col-9"
              value={newService.steps[i].type}
              disabled={!newService.steps[i].isEdit}
              onChange={(value) => {
                var temp = newService.steps;
                temp[i] = { ...temp[i], type: value };
                setNewService({ ...newService, steps: temp });
              }}
            >
              <Select.Option value="appointment">Appointment</Select.Option>
              <Select.Option value="form">Form</Select.Option>
              <Select.Option value="document">Document</Select.Option>
              <Select.Option value="information">Information</Select.Option>
              <Select.Option value="payment">Payment</Select.Option>
              <Select.Option value="sign_form">Sign Form</Select.Option>
            </Select>
          </div>

          {!loading3 &&
            (newService.steps[i].type == "form" ||
              newService.steps[i].type == "appointment" ||
              newService.steps[i].type == "sign_form") && (
              <div className="d-flex flex-row gap-5 mb-4 align-items-center">
                <span style={{ display: "flex" }}>
                  <div
                    style={{
                      color: "#ff4d4f",
                      fontSize: "18px",
                      marginRight: "5px",
                    }}
                  >
                    *
                  </div>
                  Event:{" "}
                </span>

                <Select
                  className="col-9"
                  value={
                    newService.steps[i].modelId
                      ? newService.steps[i].modelId
                      : null
                  }
                  disabled={!newService.steps[i].isEdit}
                  onChange={(value) => {
                    var temp = newService.steps;
                    temp[i] = { ...temp[i], modelId: value };
                    setNewService({
                      ...newService,
                      steps: temp,
                    });
                  }}
                >
                  {event[newService.steps[i].type]
                    ? event[newService.steps[i].type].map((e) => {
                        return e ? (
                          <Select.Option value={e.id}>{e.title}</Select.Option>
                        ) : null;
                      })
                    : null}
                </Select>
              </div>
            )}
          {!loading3 && newService.steps[i].type == "payment" && (
            <div className="d-flex flex-row gap-5 mb-4 align-items-center">
              <span style={{ display: "flex" }}>
                <div
                  style={{
                    color: "#ff4d4f",
                    fontSize: "18px",
                    marginRight: "5px",
                  }}
                >
                  *
                </div>
                Price:{" "}
              </span>

              <InputNumber
                className="col-9"
                value={newService.steps[i].price}
                disabled={!newService.steps[i].isEdit}
                onChange={(e) => {
                  var temp = newService.steps;
                  temp[i] = {
                    ...temp[i],
                    price: e,
                  };
                  setNewService({ ...newService, steps: temp });
                }}
              />
            </div>
          )}

          <div className="d-flex flex-row gap-2 mb-4 align-items-center">
            <span style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Description:{" "}
            </span>

            <Input.TextArea
              className="col-9"
              value={newService.steps[i].description}
              disabled={!newService.steps[i].isEdit}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = {
                  ...temp[i],
                  description: e.target.value,
                };
                setNewService({ ...newService, steps: temp });
              }}
            />
          </div>

          <div className="d-flex flex-row gap-2 mb-4 align-items-center">
            <span style={{ display: "flex" }}>Send Notification by: </span>

            <Radio.Group
              defaultValue={"both"}
              disabled={!newService.steps[i].isEdit}
            >
              <Radio value={"email"}>Email</Radio>
              <Radio value={"text"}>Text Message</Radio>
              <Radio value={"both"}>Both</Radio>
            </Radio.Group>
          </div>
        </div>
        <div
          style={
            isMobile ? { width: "100%" } : { width: "45%", paddingLeft: "35px" }
          }
          className={`${isMobile ? "px-2" : ""}`}
        >
          <FormItem
            // labelCol={12}
            label={"Starts at"}
            name={`start` + i}
            initialValue={newService.steps[i].startDays}
            rules={[{ required: true, message: "Please add start days!" }]}
          >
            <InputNumber
              value={newService.steps[i].startDays}
              disabled={!newService.steps[i].isEdit}
              keyboard={true}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = { ...temp[i], startDays: e };
                setNewService({ ...newService, steps: temp });
              }}
            />
            <span style={{ marginLeft: "8px" }}> days after activate.</span>
          </FormItem>
          <FormItem
            rules={[{ required: true, message: "Please add expires days!" }]}
            style={{ marginBottom: "18px" }}
            label={"Expires at"}
            name={`expire` + i}
            initialValue={newService.steps[i].expireDays}
          >
            <InputNumber
              value={newService.steps[i].expireDays}
              disabled={!newService.steps[i].isEdit}
              keyboard={true}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = { ...temp[i], expireDays: e };
                setNewService({ ...newService, steps: temp });
              }}
            />
            <span style={{ marginLeft: "8px" }}> days after starting day.</span>
          </FormItem>
          <Form.Item
            style={{ marginBottom: "12px" }}
            label="Requires admin’s approval"
            labelCol={12}
          >
            <Switch
              checked={newService.steps[i].needAdminApproved}
              onChange={(v) => {
                var temp = newService.steps;
                temp[i] = {
                  ...temp[i],
                  needAdminApproved: v,
                };
                setNewService({ ...newService, steps: temp });
              }}
              disabled={!newService.steps[i].isEdit}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "12px" }}
            label="Requires user’s approval"
            labelCol={12}
          >
            <Switch
              checked={newService.steps[i].needUserApproved}
              onChange={(v) => {
                var temp = newService.steps;
                temp[i] = {
                  ...temp[i],
                  needUserApproved: v,
                };
                setNewService({ ...newService, steps: temp });
              }}
              disabled={!newService.steps[i].isEdit}
            />
          </Form.Item>
          {loading2 ? (
            <Spin />
          ) : (
            privilage.indexOf(access.serviceManegment.getCondition) > -1 &&
            newService.steps[i].id && (
              <h2
                style={
                  newService.steps[i].isEdit
                    ? {
                        color: "#2E6CDF",
                        fontWeight: "700",
                        marginTop: "-8px",
                        cursor: "pointer",
                      }
                    : {
                        color: "rgba(106, 105, 105, 1)",
                        fontWeight: "700",
                        marginTop: "10px",
                        cursor: "pointer",
                      }
                }
                onClick={async () => {
                  if (newService.steps[i].isEdit) {
                    var res = await getCondition(newService.steps[i]);
                    if (res) {
                      setAddCond(true);
                    }
                  }
                }}
              >
                Conditions
              </h2>
            )
          )}
        </div>
      </div>
      {newService.steps[i].isEdit &&
        privilage.indexOf(access.serviceManegment.addStep) > -1 &&
        privilage.indexOf(access.serviceManegment.editStep) > -1 && (
          <Button
            style={
              isMobile
                ? { marginBottom: "20px", width: "25%", marginTop: "30px" }
                : {
                    marginLeft: "8%",
                    marginBottom: "20px",
                    width: "10%",
                  }
            }
            loading={isLoading && newService.steps[i].isEdit}
            onClick={() => {
              if (newService.steps[i].isEdit && !newService.steps[i].id)
                postStep(newService.steps[i], i);
              if (newService.steps[i].isEdit && newService.steps[i].id)
                editStep(newService.steps[i], i);
            }}
            type={newService.steps[i].isEdit ? "primary" : "default"}
          >
            {newService.steps[i].id ? "Save" : "Add"}
          </Button>
        )}
      <div className="h-line" />
    </>
  );
};

export default AddService;
