const full = [
  "index_appointment_event",
  "show_user",
  "index_user",
  "disable_user",
  "enable_user",
  "create_user",
  "update_user",
  "show_user_profile",
  "update_user_profile",
  "index_otp",
  "",
  "show_patient",
  "create_patient",
  "verify_patient",
  "disable_patient",
  "index_patient",
  "show_patient",
  "index_patient",
  "disable_patient",
  "create_patient",
  "verify_patient",

  "update_patient",
  "update_patient_service_type",
  "update_patient_teacher",
  "update_patient_referral_by",
  "update_patient_emergency_contact",

  "index_form",
  "update_form_access",

  "index_document",
  "create_document",
  "update_document",
  "delete_document",
  "index_service",
  "index_purchase",
  "show_service",
  "show_purchase",
  "create_service",
  "create_purchase",
  "update_purchase",
  "approve_purchase",
  "update_purchase_status",

  "update__purchase_step",
  "index_purchase_reply",
  "create_purchase_reply",
  "update_purchase_reply",
  "delete_purchase_reply",
  "read_purchase_reply",
  "show_appointment",
  "index_document",
  "approve_purchase_step",
  "generate_purchase_appointment_step_url",
  "show_appointment",
  "show_appointment",
  "show_appointment",
  "resend_sign_form_request",
  "preview_sign_form_request",
  "decline _purchase_step",

  "index_privilege",
  "index_role",
  "index_user",
  "create_privilege",
  "update_privilege",
  "delete_privilege",
  "index_report",
  "show_report",
  "create_report",
  "delete_report",
  "create_report_section",
  "update_report_section",
  "delete_report_section",
  "create_report_section",
  "update_report_question",
  "delete_report_question",
  "create_report_command",
  "update_report_command",
  "delete_report_command",
  "show_report_benchmark",
  "update_report_result",
  "index_comment",
  "create_role",
  "update_role",
  "delete_role",
  "update_role",
  "update_role",

  "show_form",
  "index_form",
  "update_form",
  "delete_form",
  "update_form_access",
  "create_form",
  "create_appointment_event",
  "update_appointment_event",
  "index_appointment_event",
  "delete_appointment_event",
  "create_document",
  "update_document",
  "index_document",
  "delete_document",
  "index_service",
  "show_service",
  "create_service",
  "update_service",
  "delete_service",
  "publish_service",
  "create_Step",
  "update_service_step",
  "create_service_step_condition",
  "delete_service_step",
  "index_service_step_condition",
  "delete_service_step_condition",
  "delete_service_step_block",
  "index_service_step_block",
  "create_service_step_block",
  "show_patient",
  "verify_patient",
  "index_purchase_step",
  "approve_purchase_step",
  "decline_purchase_step",
  "show_appointment",
  "show_appointment_event",
  "update_appointment",
  "create_comment",
  "update_appointment",
  "update_purchase_step",
  "create_purchase_reply",
  "show_purchase",
  "approve_purchase",
  "index_purchase_reply",
  "index_user",
  "read_purchase_reply",
  "index_comment",
  "create_comment",
  "update_comment",
  "delete_comment",
  "delete_comment_attachment",
  "create_comment_attachment",
];
export default full;
