import { range, readArrayBuffer } from './utils.ts';

/**
 * slice AudioBuffer from start byte to end byte
 */
export function sliceAudioBuffer(audioBuffer: AudioBuffer, start = 0, end = audioBuffer.length) {
  const newBuffer = new AudioContext().createBuffer(
    audioBuffer.numberOfChannels,
    end - start,
    audioBuffer.sampleRate,
  );

  for (let i = 0; i < audioBuffer.numberOfChannels; i += 1) {
    newBuffer.copyToChannel(audioBuffer.getChannelData(i).slice(start, end), i);
  }

  return newBuffer;
}

/**
 * serialize AudioBuffer for message send
 */
export function serializeAudioBuffer(audioBuffer: AudioBuffer) {
 
  return {
    channels: [audioBuffer.getChannelData(0) , audioBuffer.numberOfChannels - 1 >= 1 ? audioBuffer.getChannelData(1): []],
    // range(0, audioBuffer.numberOfChannels - 1)
    // .map((i) => {return audioBuffer.getChannelData(i)}
    // ),
    sampleRate: audioBuffer.sampleRate,
    length: audioBuffer.length,
  };
}

export async function decodeAudioBuffer(blob: Blob) {
  const arrayBuffer = await readArrayBuffer(blob);
  const audioBuffer = await new AudioContext().decodeAudioData(arrayBuffer);

  return audioBuffer;
}
