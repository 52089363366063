import Logo from "../assets/images/Logo.png";
import LightLogo from "../assets/images/LightLogo.png";
import Profile from "../assets/images/profile.png";
import plusIcon from "../assets/images/plusIcon.png";
import minesIcon from "../assets/images/minesIcon.png";
import service from "../assets/images/service.png";
import blur from "../assets/images/blur.jpg";
import noImg from "../assets/images/noimg.png";
const assets = {
  getFile: (name) => assets.files[name],
  files: {
    noImg,
    service,
    Logo,
    LightLogo,
    Profile,
    plusIcon,
    minesIcon,
    blur,
  },
};

export const useAssets = () => assets;
