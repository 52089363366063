import { React, useState, useRef, useEffect } from "react";
import "./styles.scss";
import { Form, Table, Button, Space, Input, Modal } from "antd";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { useMobile } from "../../hooks";
import "react-accessible-accordion/dist/fancy-example.css";
import Highlighter from "react-highlight-words";
import { fetchData, postData, putData, deleteData } from "../../endpoints";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const DocumentsManegmant = () => {
  const isMobile = useMobile();
  const searchInput = useRef(null);
  const { confirm } = Modal;
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [titles, setTitles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newTitle, setNewTitle] = useState({});

  const fetchTitle = async () => {
    setIsLoading(true);
    var res = await fetchData("admin/document-category");
    if (res.status == 200) {
      setTitles(res.data.data);
    }
    setIsLoading(false);
  };
  const addTitle = async () => {
    setIsLoading(true);
    var res = await postData("admin/document-category", newTitle);
    if (res.status == 201) {
      setNewTitle({});
      setIsOpen(false);

      await fetchTitle();
      Modal.success({ title: "Category added successfuly!" });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const editTitle = async () => {
    setIsLoading(true);
    var res = await putData("admin/document-category/" + newTitle.id, newTitle);
    if (res.status == 200) {
      setIsOpen(false);
      setIsEdit(false);
      setNewTitle({});
      await fetchTitle();
      Modal.success({ title: "Category edited successfuly!" });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTitle();
  }, []);

  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to remove these titles?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };
  const removeForm = async (id) => {
    setIsLoading(true);
    var res = await deleteData(`admin/document-category/${id}`);
    if (res.status === 200) {
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };
  const removeGrupe = async (listOfForms) => {
    let isOk = true;
    await listOfForms.map(async (e) => {
      var res = await removeForm(e.id);
      if (!res) {
        isOk = false;
      }
    });
    if (isOk) {
      await fetchTitle();
      setSelectedRowKeys([]);
      Modal.success({ title: "Category has been successfully deleted." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "60%" : "40%",
      ...getColumnSearchProps("title"),
    },

    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div
          style={{ marginRight: "15px" }}
          dir="rtl"
          className="d-flex flex-row gap-4"
        >
          {privilage.indexOf(access.documentManegment.delete) > -1 && (
            <span
              style={{
                color: "#BC2323",

                cursor: "pointer",
              }}
              onClick={async () => {
                await showDeleteConfirm([u]);
              }}
            >
              Remove
            </span>
          )}

          {privilage.indexOf(access.documentManegment.edit) > -1 && (
            <span
              style={{
                color: "black",

                cursor: "pointer",
              }}
              onClick={async () => {
                setNewTitle(u);
                setIsEdit(true);
                setIsOpen(true);
              }}
            >
              Edit
            </span>
          )}
        </div>
      ),
    },
  ];
  return (
    <div
      style={{ backgroundColor: "white", minHeight: "100vh" }}
      className="d-flex flex-column w-100"
    >
      <div className={`${isMobile ? "col-11 d-flex flex-column mx-3 " : ""}`}>
        <div
          className={`${
            isMobile
              ? "document-category d-flex flex-column align-items-center justify-content-center  px-2 my-5 gap-4"
              : "document-category d-flex flex-column align-items-center justify-content-center py-5 px-5 my-5 gap-4"
          }`}
        >
          <h1
            style={
              isMobile
                ? { fontSize: "18px", fontWeight: "700" }
                : { fontSize: "24px", fontWeight: "700" }
            }
          >
            Document Category
          </h1>
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            columns={columns}
            dataSource={titles.filter((f) => {
              return !f.deletedAt;
            })}
            size="small"
            style={{ width: "100%" }}
            footer={() => {
              if (privilage.indexOf(access.documentManegment.add) > -1)
                return (
                  <div
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    className="d-flex fle-row gap-2 px-4 align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <PlusOutlined
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                      }}
                    />
                    <h6
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                        marginBottom: "0",
                      }}
                    >
                      Add Title
                    </h6>
                  </div>
                );
            }}
          />
        </div>
      </div>
      <Modal
        style={{ top: "15vh" }}
        open={isOpen}
        confirmLoading={isLoading}
        okText={isEdit ? "Edit" : "Add"}
        icon={false}
        onOk={async () => {
          if (isEdit) {
            await editTitle();
          } else {
            await addTitle();
          }
        }}
        onCancel={() => {
          setIsEdit(false);
          setNewTitle({});
          setIsOpen(false);
        }}
      >
        {AddTitle({ newTitle, setNewTitle, isEdit })}
      </Modal>
    </div>
  );
};
const AddTitle = ({ newTitle, setNewTitle, isEdit }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center px-4 py-4">
      <h2 style={{ fontSize: "30px", fontWeight: "600" }}>
        {isEdit ? "Edit" : "Add"} Title
      </h2>
      <Form
        name="edit form"
        autoComplete="off"
        style={{ width: "100%" }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item label={"Title"} initialValue={newTitle.title}>
          <Input
            value={newTitle.title}
            onChange={(e) => {
              setNewTitle({ ...newTitle, title: e.target.value });
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
export default DocumentsManegmant;
