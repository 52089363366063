import { React, useState, useEffect } from "react";
import { Button, Form } from "antd";
import "./styles.scss";
import { useAssets, useMobile } from "../../hooks/index";
import Cookies from "js-cookie";
import ReactCodeInput from "react-code-input";
import { useNavigate } from "react-router-dom";
import { postWithoutToket } from "../../endpoints";
import Swal from "sweetalert2";

const Authentication = ({ Secret }) => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const email = Cookies.get("email");
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [counter, setCounter] = useState(180);
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState(Secret);
  const navigator = useNavigate();
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const props = {
    inputStyle: {
      outline: "none",
      MozAppearance: "textfield",
      margin: "3%",
      width: "10%",
      fontSize: "18px",
      textAlign: "center",
      borderBottom: "2px solid #212529",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    },
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    var data = { code: parseInt(pinCode), secret: secret, identifier: email };

    var res = await postWithoutToket("auth/verify", JSON.stringify(data));
    if (res.status != 201) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    } else {
      Cookies.set("access_token", res.data.data.token);
      navigator("/chang-password", { state: { email: email } });
    }
    setIsLoading(false);
  };

  const handlePinChange = (pinCode) => {
    if (pinCode === "") setIsPinCodeValid(true);
    setPinCode(pinCode);
  };

  const handleResend = async () => {
    if (counter <= 0) {
      await sendCode();
      setCounter(180);
    }
  };

  const sendCode = async () => {
    setIsLoading(true);
    var body = { email: email, password: "" };
    var res = await postWithoutToket("auth/login", JSON.stringify(body));
    if (res.status != 201) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    } else {
      setSecret(res.data.data.secret);
    }
    setIsLoading(false);
  };

  return (
    <div className="background">
      {isMobile ? (
        <img
          src={getFile("Logo")}
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "50vw",
            zIndex: "3",
          }}
        />
      ) : (
        <img
          src={getFile("LightLogo")}
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "20vw",
          }}
        />
      )}
      <div className="auth-box px-4">
        <h1>Authentication</h1>
        <h2>Please enter the code we sent to {email}</h2>
        <Form
          size="large"
          name="authentication"
          layout={"vertical"}
          style={{ maxWidth: 900, width: "80%" }}
          autoComplete="off"
        >
          <Form.Item
            rules={[{ required: true, message: "Please enter the code!" }]}
          >
            <ReactCodeInput
              type="number"
              fields={6}
              isValid={isPinCodeValid}
              onChange={handlePinChange}
              value={pinCode}
              {...props}
            />
            <div
              className="d-flex flex-row gap-5"
              style={{ marginLeft: "20%", width: "60%", marginTop: "30px" }}
            >
              <h3
                className={
                  counter > 0 ? "disable pr-2 w-75" : "enable pr-2 w-75"
                }
                onClick={handleResend}
              >
                Resend Code
              </h3>
              <h3 style={{ textAlign: "right", width: "25%" }}>
                {Math.floor(counter / 60)}:{counter % 60}
              </h3>
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 4, offset: !isMobile ? 10 : 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Authentication;
