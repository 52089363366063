var accessList = {
  userManegment: {
    showUser: "show_user",
    indexUser: "index_user",
    disableUser: "disable_user",
    enableUser: "enable_user",
    addUser: "create_user",
    editUser: "update_user",
    showProfile: "show_user_profile",
    editProfile: "update_user_profile",
    showDivice: "index_otp",
    logOutDivice: "",
    showPatient: "show_patient",
    addPatient: "create_patient",
    verifyPatient: "verify_patient",
    disablePatient: "disable_patient",
    indexPatient: "index_patient",
  },
  patientManegment: {
    showPatient: "show_patient",
    indexPatient: "index_patient",
    disablePatient: "disable_patient",
    addPatient: "create_patient",
    verifyPatient: "verify_patient",
    editPatient: {
      personal: "update_patient",
      serviceType: "update_patient_service_type",
      teacher: "update_patient_teacher",
      refferral: "update_patient_referral_by",
      emerjency: "update_patient_emergency_contact",
    },
    form: {
      showForm: "index_form",
      assignForm: "update_form_access",
    },
    document: {
      showDocument: "index_document",
      addDocument: "create_document",
      editDocument: "update_document",
      removeDocument: "delete_document",
    },
    service: {
      indexService: "index_service",
      indexpurchase: "index_purchase",
      showService: "show_service",
      showpurchase: "show_purchase",
      addService: "create_service",
      addpurchase: "create_purchase",
      editpurchase: "update_purchase",
      done: "approve_purchase",
      cancel: "update_purchase_status",
      step: {
        editStatus: "update__purchase_step",
        showReply: "index_purchase_reply",
        addReply: "create_purchase_reply",
        editReply: "update_purchase_reply",
        deleteReply: "delete_purchase_reply",
        readReply: "read_purchase_reply",
        showApoointment: "show_appointment",
        showDocument: "index_document",
        approve: "approve_purchase_step",
        generateLink: "generate_purchase_appointment_step_url",
        bookNow: "show_appointment",
        rescchole: "show_appointment",
        cancel: "show_appointment",
        Resend: "resend_sign_form_request",
        preiew: "preview_sign_form_request",
        Reject: "decline _purchase_step",
      },
    },
    report: {
      index: "index_report",
      show: "show_report",
      add: "create_report",
      addTemplate: "",
      showTemplate: "",
      delete: "delete_report",
      addSection: "create_report_section",
      editSection: "update_report_section",
      deleteSection: "delete_report_section",
      addQuestion: "create_report_section",
      editQuestion: "update_report_question",
      deleteQuestion: "delete_report_question",
      addCommand: "create_report_command",
      editCommand: "update_report_command",
      deleteCommand: "delete_report_command",
      runCommand: "",
      editResult: "",
      runResult: "",
      benchMark: "show_report_benchmark",
      upload: "update_report_result",
    },
    comment: {
      index: "index_comment",
    },
  },
  adminManegment: {
    showPrivilage: "index_privilege",
    showRole: "index_role",
    showAdmin: "index_user",
    addPrivilage: "create_privilege",
    editPrivilage: "update_privilege",
    deletePrivilage: "delete_privilege",
    addRole: "create_role",
    editRole: "update_role",
    deleteRole: "delete_role",
    addAdmin: "update_role",
    deleteAdmin: "update_role",
  },
  formManegment: {
    show: "show_form",
    index: "index_form",
    edit: "update_form",
    delete: "delete_form",
    assign: "update_form_access",
    add: "create_form",
  },
  eventManegment: {
    add: "create_appointment_event",
    edit: "update_appointment_event",
    index: "index_appointment_event",
    delete: "delete_appointment_event",
  },
  documentManegment: {
    add: "create_document",
    edit: "update_document",
    index: "index_document",
    delete: "delete_document",
  },
  serviceManegment: {
    index: "index_service",
    show: "show_service",
    add: "create_service",
    edit: "update_service",
    delete: "delete_service",
    publish: "publish_service",
    addStep: "create_Step",
    editStep: "update_service_step",
    addCondition: "create_service_step_condition",
    delteStep: "delete_service_step",
    getCondition: "index_service_step_condition",
    deleteCondition: "delete_service_step_condition",
    deleteBlock: "delete_service_step_block",
    indexBlock: "index_service_step_block",
    createBlock: "create_service_step_block",
  },
  todoList: {
    showPatient: "show_patient",
    indexPatient: "index_patient",
    indexEvent: "index_appointment_event",
    verifyPatient: "verify_patient",
    showStep: "index_purchase_step",
    approve: "approve_purchase_step",
    reject: "decline_purchase_step",
    showappointment: "show_appointment",
    showEvent: "show_appointment_event",
    editappointment: "update_appointment",
    addComment: "create_comment",
    check: "update_appointment",
    changeStatus: "update_purchase_step",
    addReply: "create_purchase_reply",
    showpurchase: "show_purchase",
    doneService: "approve_purchase",
    indexReply: "index_purchase_reply",
    indexUser: "index_user",
    readReply: "read_purchase_reply",
    indexComment: "index_comment",
    addComment: "create_comment",
    editComment: "update_comment",
    deleteComment: "delete_comment",
    deleteAttachment: "delete_comment_attachment",
    addAttachment: "create_comment_attachment",
  },
};

export default accessList;
