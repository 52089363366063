import React from "react";
import { Table, Modal, Input, Space, Button, Tag } from "antd";
import { useState, useEffect, useRef } from "react";
import { fetchData, putData } from "../../endpoints";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useMobile } from "../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
function Unverified() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMobile();
  const [dataSource, setdataSource] = useState([]);
  const [selectedpatient, setselectedpatient] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [current, setCurrent] = useState(1);

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdataSource([]);
      await getpatient(1, true);
    } else {
      let search = "";
      if (dataIndex == "userId") search = "userId";
      if (dataIndex == "name") search = "firstName";

      var res = await fetchData(
        `admin/patient/?verifiedAt=false&includes[]=user&includes[]=emergencyContacts&includes[]=serviceTypes&${search}=` +
          selectedKeys[0]
      );
      // console.log(res);
      if (res.status == 200) {
        res.data.data.map((temp) => {
          temp["name"] = temp.firstName + " " + temp.lastName;
          let date = new Date(temp.createdAt);
          let year = date.getFullYear();
          let month = date.getMonth();
          let day = date.getDay();
          temp["date"] = `${year}/${month}/${day}`;
        });

        setdataSource(res.data.data);
      }
    }
    setisLoading(false);
  };

  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getpatient(1, true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const isComplate = (p) => {
    if (!p.firstName) {
      return false;
    }
    if (!p.lastName) {
      return false;
    }
    if (!p.birthday) {
      return false;
    }
    if (!p.sex) {
      return false;
    }
    if (!p.gender) {
      return false;
    }
    if (!p.careCard) {
      return false;
    }
    if (p.emergencyContacts.length == 0) {
      return false;
    }

    if (p.serviceTypes.length === 0) {
      return false;
    }
    return true;
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "25%",
      ...getColumnSearchProps("name"),
      render: (u) =>
        u ? (
          <a style={{ color: "black" }} href={"/patients/" + u.id}>
            {u.firstName + "  " + u.lastName}
          </a>
        ) : (
          <></>
        ),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "20%",
      render: (u) =>
        u && (
          <div>
            <a href={"/users/" + u.id} style={{ color: "black" }}>
              {u.firstName} {u.lastName}
            </a>
          </div>
        ),
      // ...getColumnSearchProps("userId"),
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "10%",
    },
    {
      title: "Information",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (u) => (
        <div
          style={
            isComplate(u) ? { color: "rgba(36, 140, 0, 1)" } : { color: "red" }
          }
        >
          {isComplate(u) ? "" : "InComplete"}
        </div>
      ),
    },
    privilage.indexOf(access.todoList.verifyPatient) > -1 && {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (u) => (
        <Button
          size={isMobile ? "small" : "default"}
          onClick={() => {
            if (isComplate(u)) {
              setselectedpatient(u);
              setIsModalOpen(true);
            }
          }}
        >
          <a style={{ fontSize: "11px" }}>
            {isComplate(u) ? "Verify" : "Send Remainder"}
          </a>
        </Button>
      ),
    },
    Table.EXPAND_COLUMN,
    // {
    //   title: "Email",
    //   dataIndex: "user",
    //   key: "user",
    //   width: "10%",
    //   render: (u) => (
    //   u&&  <div style={{ display: "flex", fontSize: "10px" }}>{u.email}</div>
    //   ),
    //   // ...getColumnSearchProps("userId"),
    // },
    // {
    //   title: "Phone Number",
    //   dataIndex: "user",
    //   key: "user",
    //   width: "10%",
    //   render: (u) => (
    // u&&    <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-around",
    //         fontSize: "10px",
    //       }}
    //     >
    //       {u.phoneNumber}
    //     </div>
    //   ),
    //   // ...getColumnSearchProps("userId"),
    // },
  ];

  async function getpatient(page = 1, reload = false) {
    setCurrent(page);
    setisLoading(true);
    if (dataSource.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/patient/?verifiedAt=false&includes[]=user&includes[]=serviceTypes&includes[]=emergencyContacts&perPage=15&page=" +
          page
      );
      var temp = dataSource;
      if (reload) {
        temp = [];
      }
      if (res.status == 200) {
        if (res.data.data.length < 15) {
          res.data.data.map((temp) => {
            temp["name"] = temp.firstName + " " + temp.lastName;
            let date = new Date(temp.createdAt);
            let year = date.getFullYear();
            let month = date.getMonth();
            let day = date.getDay();
            temp["date"] = `${year}/${month}/${day}`;
          });
          setdataSource(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data])
          );
        } else {
          res.data.data.map((temp) => {
            temp["name"] = temp.firstName + " " + temp.lastName;

            let date = new Date(temp.createdAt);
            let year = date.getFullYear();
            let month = date.getMonth();
            let day = date.getDay();
            temp["date"] = `${year}/${month}/${day}`;
          });
          setdataSource(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
      //
    }
    setisLoading(false);
  }
  // function removeDuplicates(arr) {
  //   return arr.filter((item, index) => arr.indexOf(item) === index);
  // }
  useEffect(() => {
    getpatient();
    // getpatientmodal();
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",

                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "70px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "40px 0px",
                    fontSize: "24px",
                  }
            }
          >
            <strong>Unverified Patients</strong>
          </div>
          <div style={isMobile ? { minWidth: "80vw" } : {}}>
            <Table
              scroll={{ x: "auto" }}
              size="small"
              loading={isLoading}
              columns={columns}
              dataSource={dataSource.map((d) => {
                return { ...d, key: d.id };
              })}
              pagination={{
                onChange: async (page) => {
                  await getpatient(page);
                },
                pageSize: 15,
                current: current,
              }}
              expandable={{
                expandedRowRender: (record) =>
                  record.user ? (
                    <div>
                      <div className="d-flex flex-row gap-2">
                        <label style={{ fontWeight: "bold" }}>Email:</label>
                        <p>{record.user.email}</p>
                      </div>
                      <div className="d-flex flex-row gap-2">
                        <label style={{ fontWeight: "bold" }}>
                          Phone Number:
                        </label>
                        <p>{record.user.phoneNumber}</p>
                      </div>
                    </div>
                  ) : (
                    <div>user not found</div>
                  ),
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title="verify"
        open={isModalOpen}
        okText="Verify"
        confirmLoading={isLoading}
        onCancel={() => setIsModalOpen(false)}
        onOk={async () => {
          setisLoading(true);
          var res = await putData(`admin/patient/${selectedpatient.id}/verify`);
          if (res.status == 200) {
            Modal.success({ title: "Patient verified successfully" });
            setIsModalOpen(false);
            getpatient(1, true);
          }
          setisLoading(false);
        }}
      >
        <p>
          Are you sure to verify <strong>{selectedpatient.name}</strong>?
        </p>
      </Modal>
      {/* <Modal
        open={SearchUserModal}
        title="Search User"
        width={1000}
        // onOk={handleOk}
        onCancel={() => {
          setSearchUserModal(false);
        }}
      >
        <Table
          loading={isLoading}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          columns={columns2}
          onChange={filterServiceType}
          dataSource={data}
          size="small"
          style={{ width: "100%" }}
          pagination={{
            onChange: async (page) => {
              await getpatientmodal(page);
            },
            // total: 50,
            pageSize: 15,
          }}
        />
      </Modal> */}
    </>
  );
}
export default Unverified;
