import axios from "axios";
import { API_BASE_URL } from "./ApiConfig";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Navi = (props) => {
  const navigator = useNavigate();
  navigator(props);
};

const fetchData = async (url) => {
  const navigator = Navi;
  const response = await axios
    .get(API_BASE_URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  if (response.status == 403) {
    navigator("sign-in");
  }
  return response;
};

const fetchDataWithParams = async (url, params) => {
  const navigator = Navi;
  const response = await axios
    .get(API_BASE_URL + url, {
      params: { params },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.message;
    });
  if (response.status == 403) {
    navigator("sign-in");
  }
  return response;
};

const postData = async (url, data) => {
  const navigator = Navi;
  const respons = await axios
    .post(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      // if (reason.response.status == 400) {
      //   return reason.response.data.data.message;
      // }
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};
const postFormData = async (url, data) => {
  const navigator = Navi;
  const respons = await axios
    .post(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Cookies.get("access_token"),
        Accept: "application/json",
      },
    })
    .catch((reason) => {
      // if (reason.response.status == 400) {
      //   return reason.response.data.data.message[0];
      // }
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};
const putFormData = async (url, data) => {
  const navigator = Navi;
  const respons = await axios
    .put(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Cookies.get("access_token"),
        Accept: "application/json",
      },
    })
    .catch((reason) => {
      // if (reason.response.status == 400) {
      //   return reason.response.data.data.message[0];
      // }
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};
const putGetFormData = async (url, data) => {
  const navigator = Navi;
  const respons = await axios
    .put(API_BASE_URL + url, data, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Cookies.get("access_token"),
        Accept:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      },
    })
    .catch((reason) => {
      // if (reason.response.status == 400) {
      //   return reason.response.data.data.message[0];
      // }
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};
const putData = async (url, data) => {
  const navigator = Navi;
  const respons = await axios
    .put(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      // if (reason.response.status == 400) {
      //   return reason.response.data.data.message[0];
      // }
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};

const postWithoutToket = async (url, data) => {
  const navigator = Navi;
  const respons = await axios
    .post(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((reason) => {
      // if (reason.response.status == 400) {
      //   return reason.response.data.data.message[0];
      // }
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};

const deleteData = async (url) => {
  const navigator = Navi;
  const respons = await axios
    .delete(API_BASE_URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  if (respons.status == 403) {
    navigator("sign-in");
  }
  return respons;
};

export {
  fetchData,
  postData,
  postWithoutToket,
  putData,
  deleteData,
  postFormData,
  putFormData,
  putGetFormData,
  fetchDataWithParams,
};
